import { combineReducers, createStore, applyMiddleware } from 'redux';
import { all } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import { userSagas, userReducer } from 'components/dashboard/Users/ducks';
import storefrontRoom from 'components/storefront/Room/ducks';
import { roomSagas, roomReducer } from 'components/dashboard/Rooms/ducks';
import { settingSagas, settingReducer } from 'components/dashboard/Settings/ducks';
import { messagesSagas, messagesReducer } from 'components/dashboard/Messages/ducks';
import { ipAddressSagas, ipAddressReducer } from 'components/dashboard/IpAddresses/ducks';
import { colorSagas, colorReducer } from 'components/dashboard/Colors/ducks';

const rootReducer = combineReducers({
  user: userReducer,
  storefrontRoom: storefrontRoom.roomReducer,
  room: roomReducer,
  setting: settingReducer,
  messages: messagesReducer,
  ipAddress: ipAddressReducer,
  colors: colorReducer,
});

export const rootSaga = function* rootSaga() {
  yield all([
    ...userSagas,
    ...roomSagas,
    ...storefrontRoom.roomSagas,
    ...settingSagas,
    ...messagesSagas,
    ...ipAddressSagas,
    ...colorSagas,
  ]);
};

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

export default store;
