import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import Popconfirm from 'antd-components/popConfirm';
import Button from 'antd-components/button';
import { Formik, Form } from 'formik';
import Field from 'antd-components/field';
import Input from 'antd-components/input';
import DatePicker from 'antd-components/datePicker';
import { AntTable } from 'antd-components/table';
import { createAction } from 'dorothy/utils/index';
import withHeader from '../withHeader';
import { DASHBOARD_MESSAGES_REQUEST, DASHBOARD_MESSAGES_DELETE } from './ducks';
import { isEmpty } from 'lodash';
import moment from 'moment';

const Container = styled.div`
  padding: 50px 100px;

  .table-box {
    margin-top: 30px;
  }
`;

const Messages = ({ messagesStore, dispatch, match, ...otherProps }) => {
  const { params } = match;

  const { messages, isFetching, pagination } = messagesStore;

  const { total = 1 } = pagination;

  const [dataSource, setDataSource] = useState([]);
  const [tableMeta, setTableMeta] = useState({});
  const [filterOptions, setFilterOptions] = useState({ roomId: params.id, pageSize: 50, page: 1 });

  const columns = [
    {
      title: '#',
      key: '_id',
      render: (text, record, index) => (
        <span>{index + (tableMeta.current ? tableMeta.current * tableMeta.pageSize : 1)}</span>
      ),
    },
    {
      title: 'Nội dung',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Người gởi',
      dataIndex: 'user',
      key: 'user',
      width: 250,
      render: (text, record, index) => (
        <div>
          <div>Tên đăng nhập: {text.username}</div>
          <div>Biệt danh: {text.fullname}</div>
        </div>
      ),
    },
    {
      title: 'Ngày gởi / IP',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 250,
      render: (text, record, index) => (
        <div>
          <div className="text-nowrap">{format(new Date(text), 'dd LLL yyyy kk:mm')}</div>
          <div className="text-nowrap">IP: {record.user.ip}</div>
        </div>
      ),
    },
    {
      title: 'Chức năng',
      render: (name, record) => {
        const { id, roomId, dataRaw } = record;
        return (
          <>
            <Popconfirm
              placement="topRight"
              title="Bạn có chắc muốn xoá?"
              onConfirm={() =>
                dispatch(createAction(DASHBOARD_MESSAGES_DELETE, { id, roomId, dataRaw }))
              }
              okText="Yes"
              cancelText="No"
            >
              <Button className="mr-2 mb-2" type="danger">
                Xoá
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const handleChange = data => {
    setTableMeta(data);
  };

  const renderForm = ({ handleSubmit, resetForm, ...form }) => {
    const { values } = form;
    const { created } = values;

    const handelResetForm = () => {
      resetForm();
      handleSubmit();
    };

    return (
      <Form className="form form-dashboard" style={{ maxWidth: 500 }}>
        <div className="field-group">
          <Field form={form} inline name="name" label="Người gởi" component={Input} />
          <Field form={form} inline name="message" label="Tin nhắn" component={Input} />
          <Field
            form={form}
            inline
            name="created"
            label="Ngày gởi"
            value={created}
            component={DatePicker}
          />
          <Field form={form} inline name="ip" label="IP" component={Input} />
        </div>
        <div className="action-box" style={{ textAlign: 'right', marginTop: 10 }}>
          <Button
            size="large"
            htmlType="button"
            onClick={handelResetForm}
            style={{ marginRight: 5 }}
          >
            Reset
          </Button>

          <Button size="large" htmlType="submit" type="primary" onClick={handleSubmit}>
            Tìm Kiếm
          </Button>
        </div>
      </Form>
    );
  };

  const handleFinish = data => {
    const { name = '', message = '', ip = '', created } = data;
    let result = messages;
    if (name) {
      result = result.filter(item => {
        const username = item?.user?.username || '';
        const fullname = item?.user?.fullname || '';
        return fullname.indexOf(name) > -1 || username.indexOf(name) > -1;
      });
    }

    if (message) {
      result = result.filter(item => item?.message.indexOf(message) > -1);
    }

    if (created) {
      const format = 'YYYY-MM-DD';
      const createdMoment = moment(created).format(format);
      result = result.filter(item => {
        const createdAtMoment = moment(item?.created_at).format(format);
        if (createdAtMoment === createdMoment) {
          return item;
        }
      });
    }

    if (ip) {
      result = result.filter(item => item?.message.indexOf(ip) > -1);
    }

    setDataSource(result);
  };

  useEffect(() => {
    dispatch(createAction(DASHBOARD_MESSAGES_REQUEST, filterOptions));
  }, [dispatch, filterOptions]);

  useEffect(() => {
    if (!isEmpty(messages)) {
      setDataSource(messages);
    }
  }, [messages]);

  return (
    <Container>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{}}
        // validationSchema={validationSchema}
        onSubmit={handleFinish}
        component={renderForm}
      />
      <div className="table-box">
        <AntTable
          bordered
          rowKey={(row, index) => row.id}
          dataSource={dataSource}
          columns={columns}
          loading={isFetching}
          onChange={handleChange}
          pagination={{
            pageSize: 50,
          }}
        />
      </div>
    </Container>
  );
};

export default connect(({ messages }) => ({
  messagesStore: messages,
}))(withHeader(Messages));
