import React from 'react';
import { useDispatch } from 'react-redux';
import { object } from 'yup';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import Field from 'antd-components/field';
import Input from 'antd-components/input';
import Button from 'antd-components/button';
import { LOGIN_DASHBOARD_REQUEST } from '../Users/ducks';
import Notification from 'antd-components/notification';
import { validation } from 'dorothy/utils/validation';

const Container = styled.div`
  background-color: #eee;
  width: 100vw;
  height: 100vh;
  position: relative;
`;

export const StyledForm = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > div {
    width: 350px;
    padding: 30px 20px 40px 20px;
    background: white;

    .form {
      > *:not(:last-child) {
        margin-bottom: 15px;
      }

      h2 {
        text-align: center;
      }

      > div:last-child {
        width: 100%;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 15px;
          margin-top: 25px;
          height: 45px;
          text-transform: uppercase;
          font-weight: 500;
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 480px) {
      width: calc(100% - 30px);
      max-width: calc(100% - 30px);
    }
  }
`;

const validationSchema = object().shape({
  username: validation.username,
  password: validation.password,
});

const Login = ({ history }) => {
  const dispatch = useDispatch();
  const initialValues = {
    username: '',
    password: '',
  };

  const renderForm = ({ handleSubmit, ...form }) => (
    <Form className="form">
      <h2>Đăng nhập</h2>
      <Field form={form} name="username" label="Tên tài khoản" component={Input} />
      <Field form={form} name="password" label="Mật khẩu" type="password" component={Input} />
      <div>
        <Button htmlType="submit" type="primary" onClick={handleSubmit}>
          Đăng nhập
        </Button>
      </div>
    </Form>
  );

  const onLogin = values => {
    dispatch({
      type: LOGIN_DASHBOARD_REQUEST,
      payload: {
        ...values,
        history,
        callBack: success => {
          if (success) Notification.success('Đăng nhập thành công');
        },
      },
    });
  };

  return (
    <Container>
      <StyledForm>
        <div>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onLogin}
            component={renderForm}
          />
        </div>
      </StyledForm>
    </Container>
  );
};

export default Login;
