import React from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Select from 'antd-components/select';

import Button from 'antd-components/button';
import Field from 'antd-components/field';
import Input from 'antd-components/input';
import Modal from 'antd-components/modal';
import { validation } from 'dorothy/utils/validation';

const StyledForm = styled.div`
  .action-box {
    display: flex;
    justify-content: flex-end;

    > button:nth-child(2) {
      margin-left: 20px;
    }
  }
`;

export default function ModalAddUser(props) {
  const { onSubmit, visible, title, onCancel, editingId, initialValues } = props;

  const validationSchema = yup.object().shape({
    username: validation.username,
    ...(!editingId && {
      password: validation.password,
      confirmPass: validation.passwordConfirmation,
    }),
  });

  const renderForm = ({ handleSubmit, ...form }) => (
    <Form className="form">
      <div className="field-group">
        <Field
          form={form}
          inline
          name="username"
          label="Tên tài khoản"
          component={Input}
          disabled={!!editingId}
        />
        <Field
          form={form}
          inline
          name="password"
          placeholder={editingId ? `leave it empty if you don't want to update` : null}
          label="Mật khẩu"
          type="password"
          component={Input}
        />
        {!editingId && (
          <Field
            form={form}
            inline
            name="confirmPass"
            label="Nhập lại mật khẩu"
            type="password"
            component={Input}
          />
        )}
        <Field
          inline
          form={form}
          name="role"
          label="Vai trò"
          options={[
            {
              name: 'USER',
              value: 'USER',
            },
            {
              name: 'MOD',
              value: 'MOD',
            },
            {
              name: 'ADMIN',
              value: 'ADMIN',
            },
          ]}
          component={Select}
        />
      </div>
      <div className="action-box">
        <Button size="large" htmlType="submit" type="primary" onClick={handleSubmit}>
          Lưu
        </Button>
        <Button size="large" onClick={onCancel}>
          Huỷ bỏ
        </Button>
      </div>
    </Form>
  );

  return (
    <Modal title={title} visible={visible} onCancel={onCancel} className="admin-side">
      <StyledForm>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          component={renderForm}
        />
      </StyledForm>
    </Modal>
  );
}
