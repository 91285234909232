import React from 'react';
import { Modal } from 'antd';
import styled from 'styled-components';
import 'antd/es/modal/style/css';

const StyledModal = styled(Modal)`
  /* stylelint-disable */
  ${props =>
    props.className === 'admin-side' &&
    `
  .ant-modal-body {
    padding: 24px !important;
  }

  .field-group {
    p.label {
      font-size: 14px;
    }

    .field.inline {
      margin-bottom: 20px;
    }
  }
  `}
`;

export default ({ children, className, ...props }) => (
  <StyledModal
    centered
    // width={600}
    footer={null}
    {...props}
    className={className}
  >
    {children}
  </StyledModal>
);
