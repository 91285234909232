import { put, call, takeLatest, fork } from 'redux-saga/effects';
import { callApi, createReducer, createAction } from 'dorothy/utils';

export const IP_LIST_REQUEST = 'IP_LIST_REQUEST';
export const IP_LIST_RESPONSE = 'IP_LIST_RESPONSE';

export const IP_ADD_REQUEST = 'IP_ADD_REQUEST';
export const IP_ADD_RESPONSE = 'IP_ADD_RESPONSE';

export const IP_DELETE_REQUEST = 'IP_DELETE_REQUEST';
export const IP_DELETE_RESPONSE = 'IP_DELETE_RESPONSE';

export const REQUEST_ERROR = 'REQUEST_ERROR';

function* requestRoom(action) {
  try {
    const response = yield call(callApi, 'GET', `/api/ban-ip`, action.payload);

    if (response) yield put(createAction(IP_LIST_RESPONSE, response));
  } catch (error) {
    // console.log(error);
  }
}

function* watchIPsRequest() {
  yield takeLatest(IP_LIST_REQUEST, requestRoom);
}

function* addIP(action) {
  const { callBack, ...data } = action.payload;
  try {
    const response = yield call(callApi, 'POST', `/api/ban-ip`, data);

    if (response) {
      yield put(createAction(IP_ADD_RESPONSE, response));
      callBack(true);
    }
  } catch (error) {
    // console.log(error);
    callBack(false);
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchAddIP() {
  yield takeLatest(IP_ADD_REQUEST, addIP);
}

function* deleteIP(action) {
  try {
    const response = yield call(callApi, 'DELETE', `/api/ban-ip/${action.payload}`);

    if (response && response.success) yield put(createAction(IP_DELETE_RESPONSE, action.payload));
  } catch (error) {
    // console.log(error);
  }
}

function* watchDeleteIP() {
  yield takeLatest(IP_DELETE_REQUEST, deleteIP);
}

const initState = {
  ipList: [],
  pagination: { currentPage: 1 },
};
const actionHandlers = {
  [IP_LIST_REQUEST]: (state, action) => ({ ...state, isFetching: true }),
  [IP_LIST_RESPONSE]: (state, action) => {
    const { data, pagination } = action.payload;
    return {
      ...state,
      isFetching: false,
      ipList: data,
      pagination,
    };
  },
  [IP_ADD_REQUEST]: (state, action) => ({ ...state, isWaitingAdd: true }),
  [IP_ADD_RESPONSE]: (state, action) => {
    const ipList = [...state.ipList, action.payload];
    return {
      ...state,
      isWaitingAdd: false,
      ipList,
    };
  },
  [IP_DELETE_RESPONSE]: (state, action) => {
    const ipList = state.ipList.filter(({ _id }) => _id !== action.payload);
    return {
      ...state,
      isWaitingAdd: false,
      ipList,
    };
  },
};

export const ipAddressReducer = createReducer(initState, actionHandlers);
export const ipAddressSagas = [fork(watchIPsRequest), fork(watchAddIP), fork(watchDeleteIP)];
