import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { object } from 'yup';
import styled from 'styled-components';
import { Formik, Form } from 'formik';

import Field from 'antd-components/field';
import Input from 'antd-components/input';
import Button from 'antd-components/button';
import Modal from 'antd-components/modal';
import Notification from 'antd-components/notification';

import {
  LOGIN_FACEBOOK_REQUEST,
  LOGIN_GOOGLE_REQUEST,
  LOGIN_STOREFRONT_REQUEST,
} from 'components/dashboard/Users/ducks';
import FacebookLogin from 'react-facebook-login';
import { useGoogleLogin } from '@react-oauth/google';
import ModalLoginPhone from '../ModalLoginPhone';
import ModalForgot from '../ModalForgot';
import ModalRegister from '../ModalRegister';
import { validation } from 'dorothy/utils/validation';
import { pxToRem } from 'utils/common';

export const StyledForm = styled.div`
    .form {
      > *:not(:last-child) {
        margin-bottom: ${pxToRem(10)};
      }

      h2 {
        text-align: center;
      }

      > div:last-child {
        width: 100%;
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 5px;
          height: 35px;
          text-transform: uppercase;
          font-weight: 500;
          width: 100%;
          font-size: 12px;
        }

        .btn-google {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    margin: 5px 0;
    background: #e8e8e8;
  }
`;

const validationSchema = object().shape({
  username: validation.username,
  password: validation.password,
});

const Login = props => {
  const { visible, title, onCancel, roomId } = props;
  const [showLoginPhoneModal, setShowLoginPhoneModal] = useState(false);
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);

  const dispatch = useDispatch();
  const initialValues = {
    username: '',
    password: '',
  };

  const handleLoginGoogle = useGoogleLogin({
    onSuccess: response => {
      const { access_token } = response;

      dispatch({
        type: LOGIN_GOOGLE_REQUEST,
        payload: {
          accessToken: access_token,
          roomId,
          callBack: success => {
            if (success) {
              onCancel && onCancel();
            }
          },
        },
      });
    },
  });

  const handleFbLoggedInSucess = response => {
    const { accessToken, id } = response;

    dispatch({
      type: LOGIN_FACEBOOK_REQUEST,
      payload: {
        accessToken,
        userId: id,
        roomId,
        callBack: success => {
          if (success) {
            onCancel && onCancel();
          }
        },
      },
    });

    // console.log(response);
  };

  const handleLoginPhoneSuccess = () => {
    onCancel && onCancel();
  };

  const handleForgotSuccess = () => {
    Notification.success('Thay đổi mật khẩu thành công!');
  };

  const renderForm = ({ handleSubmit, ...form }) => (
    <Form className="form">
      <h2>Đăng nhập</h2>
      <Field form={form} name="username" label="Tên tài khoản" component={Input} />
      <Field form={form} name="password" label="Mật khẩu" type="password" component={Input} />
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <a onClick={setShowForgotModal}>Quên mật khẩu?</a>
      </div>
      <div>
        <Button htmlType="submit" type="primary" block onClick={handleSubmit}>
          Đăng nhập
        </Button>
        <div className="line"></div>
        {/* <Button
          htmlType="submit"
          type="primary"
          block
          onClick={() => {
            setShowRegisterModal(true);
          }}
        >
          Đăng Ký
        </Button> */}
        <Button
          type="primary"
          block
          onClick={() => {
            setShowLoginPhoneModal(true);
          }}
        >
          Đăng ký bằng số điện thoại
        </Button>
        <FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_OAUTH_APP_ID}
          fields="name,email,picture"
          callback={handleFbLoggedInSucess}
          icon="fa-facebook"
          textButton="Đăng nhập bằng Facebook"
        />
        <Button className="btn-google" onClick={() => handleLoginGoogle()}>
        <i className="fa fa-google" style={{marginRight : '5px'}}></i>
          Đăng nhập bằng Google
        </Button>
      </div>
    </Form>
  );

  const onLogin = values => {
    dispatch({
      type: LOGIN_STOREFRONT_REQUEST,
      payload: {
        ...values,
        roomId,
        callBack: success => {
          if (success) {
            onCancel && onCancel();
          }
        },
      },
    });
  };

  return (
    <Modal title={title} visible={visible} onCancel={onCancel}>
      <StyledForm>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onLogin}
          component={renderForm}
        />
      </StyledForm>
      {showLoginPhoneModal && (
        <ModalLoginPhone
          visible={showLoginPhoneModal}
          title={'Đăng ký bằng số điện thoại'}
          roomId={roomId}
          onCancel={() => setShowLoginPhoneModal(false)}
          onSuccess={handleLoginPhoneSuccess}
        />
      )}
      {showForgotModal && (
        <ModalForgot
          visible={showForgotModal}
          title={'Quên mật khẩu?'}
          roomId={roomId}
          onCancel={() => setShowForgotModal(false)}
          onSuccess={handleForgotSuccess}
        />
      )}
      {showRegisterModal && (
        <ModalRegister
          visible={showRegisterModal}
          title={'Đăng ký tài khoản'}
          onCancel={() => setShowRegisterModal(false)}
          onSuccess={onCancel}
          roomId={roomId}
        />
      )}
    </Modal>
  );
};

export default React.memo(Login);
