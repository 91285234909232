import { truncate } from 'lodash';
import gPhoneNumber from 'google-libphonenumber';
const crypto = require('crypto');

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();
const regexFullDomain =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
const regexDomain =
  /(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z][a-zA-Z]{0,61}[a-zA-Z]/g;

const generateSecureImageUrl = (path, width, height) => {
  const hashKey = '97ccS^YKP%YY';
  const size = `${width}x${height}`;
  const baseUrl = 'https://cdn.xoilac.chat';
  const hash = crypto
    .createHmac('sha1', hashKey)
    .update(`${path}:${size}`)
    .digest('hex')
    .substr(0, 10);

  return `${baseUrl}/c.webp/${size}/${hash}/${path}`;
};

const defaultAvatarUrl = `${process.env.REACT_APP_IMG_URL}/uploads/avatar.png`;

const isExternalAvatar = avatar => {
  return (
    avatar &&
    (avatar.includes('googleusercontent') || avatar.includes('platform-lookaside.fbsbx.com'))
  );
};

export const getAvatar = user => {
  const { avatar, socialAvatar } = user;

  if (isExternalAvatar(avatar)) {
    return defaultAvatarUrl;
  }

  if (avatar) {
    return generateSecureImageUrl(avatar.substring(1), 60, 60);
  }

  return socialAvatar || '';
};

export const getAvatarFull = user => {
  const { avatar, socialAvatar } = user;

  if (isExternalAvatar(avatar)) {
    return defaultAvatarUrl;
  }

  return avatar ? `${process.env.REACT_APP_IMG_URL}${avatar}` : socialAvatar || '';
};

export const checkPhoneNumber = value => {
  try {
    const regionCode = 'VN';
    const phoneNumber = phoneUtil.parseAndKeepRawInput(value, regionCode);
    return phoneUtil.isValidNumberForRegion(phoneNumber, regionCode);
  } catch (error) {
    return false;
  }
};

export const testPhoneNumber = value => {
  if (!value) return false;
  const regex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
  return regex.test(value);
};

export const senderConvert = value => {
  if (!value) return '';
  return testPhoneNumber(value) ? `${value.slice(0, 7)}***` : value;
};

export const truncateString = (string, length) => {
  return truncate(string, { length });
};

export const removeDomain = string => {
  let message = string || '';
  message = message.replaceAll(regexFullDomain, '***');
  message = message.replaceAll(regexDomain, '***');
  return message;
};

export const removeTags = str => {
  if (str === null || str === '') return false;
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, '');
};

export const debounce = (method, delay, e) => {
  clearTimeout(method.timer);
  method.timer = setTimeout(() => {
    method(e);
  }, delay);
};

export const sanitise = text => {
  let sanitisedText = text;

  if (text.indexOf('<') > -1 || text.indexOf('>') > -1) {
    sanitisedText = text.replace(/</g, '&lt').replace(/>/g, '&gt');
  }

  return sanitisedText;
};

export const stripHtml = html => {
  let tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

export const pxToRem = value => {
  return `${parseFloat(value / 14).toPrecision(3)}rem`;
};
