import React, {
  useEffect,
  // lazy,
  // Suspense
} from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import store from 'store';

import { GoogleOAuthProvider } from '@react-oauth/google';

import ScrollToTop from './components/storefront/ScrollToTop';
import { UPDATE_USER } from 'components/dashboard/Users/ducks';

import Room from './components/storefront/Room';

import DashBoardLogin from 'components/dashboard/Login';
import Users from 'components/dashboard/Users';
import Rooms from 'components/dashboard/Rooms';
import Messages from 'components/dashboard/Messages';
import Settings from 'components/dashboard/Settings';
import IpAddresses from 'components/dashboard/IpAddresses';
import Colors from 'components/dashboard/Colors';

// const Room = lazy(() => import('components/storefront/Room'));
// const DashBoardLogin = lazy(() => import('components/dashboard/Login'));
// const Users = lazy(() => import('components/dashboard/Users'));
// const Rooms = lazy(() => import('components/dashboard/Rooms'));
// const Messages = lazy(() => import('components/dashboard/Messages'));
// const Settings = lazy(() => import('components/dashboard/Settings'));
// const IpAddresses = lazy(() => import('components/dashboard/IpAddresses'));
// const Colors = lazy(() => import('components/dashboard/Colors'));

const App = ({ dispatch }) => {
  useEffect(() => {
    const user = localStorage.getItem('user');
    store.dispatch({ type: UPDATE_USER, payload: JSON.parse(user) });
  }, [dispatch]);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_APP_ID}>
        <Switch>
          <Route exact path="/room/:roomId" component={Room} />
          <Route exact path="/dashboard/login" component={DashBoardLogin} />
          <Route exact path="/dashboard/users" component={Users} />
          <Route exact path="/dashboard/rooms" component={Rooms} />
          <Route exact path="/dashboard/rooms/:id/messages" component={Messages} />
          <Route exact path="/dashboard/settings" component={Settings} />
          <Route exact path="/dashboard/ip-addresses" component={IpAddresses} />
          <Route exact path="/dashboard/colors" component={Colors} />
        </Switch>
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
};

export default App;
