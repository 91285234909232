import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Button from 'antd-components/button';
import { Formik, Form, FieldArray } from 'formik';
import Field from 'antd-components/field';
import ColorPicker from 'antd-components/colorPicker';
import { createAction } from 'dorothy/utils/index';
import withHeader from '../withHeader';
import { COLORS_REQUEST, COLORS_SAVE_REQUEST } from './ducks';
import Notification from 'antd-components/notification';
import Spinner from 'antd-components/spinner';

const Container = styled.div`
  padding: 50px 100px;

  .table-box {
    margin-top: 30px;
  }
`;

const ColorsInputContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  .field {
    margin: 0 12px;
    flex-basis: 20%;
    &:last-child {
      margin: 0;
    }
    .label {
      width: 80px;
    }
  }
`;

const ColorsInputItem = styled.div`
  display: flex;
  margin-right: 15px;
  /* flex-wrap: wrap;
  .field {
    padding: 0 12px;
    flex-basis: 20%;
    .label {
      width: 80px;
    }
  } */
`;

const ColorsInputLabel = styled.span`
  display: inline-block;
  text-align: center;
  min-width: 70px;
  padding: 3px 5px;
  border-radius: 3px;
  background: ${props => props.background};
  color: ${props => props.text};
`;

const Colors = ({ colorsStore, dispatch, match, ...otherProps }) => {
  const { colors, colorsText, isFetched, isFetching } = colorsStore;

  const handleSave = data => {
    dispatch(
      createAction(COLORS_SAVE_REQUEST, {
        ...data,
        callBack: success => {
          if (success) {
            Notification.success('Lưu thành công');
          }
        },
      }),
    );
  };

  const renderForm = ({ handleSubmit, resetForm, ...form }) => {
    const { values } = form;
    const { colors, colorsText } = values;

    return (
      <Form className="form form-dashboard">
        <div className="field-group">
          {/* <Field form={form} inline name="name" label="Người gởi" component={ColorPicker} /> */}
          <FieldArray
            name="colors"
            render={arrayHelpers => (
              <ColorsInputContent>
                {colors.map((color, index) => {
                  return (
                    <ColorsInputItem key={index}>
                      <Field
                        form={form}
                        inline
                        name={`colors.${index}`}
                        label={
                          <ColorsInputLabel background={color} text={colorsText[index]}>{`Level ${
                            index + 1
                          }`}</ColorsInputLabel>
                        }
                        component={ColorPicker}
                      />
                      <Field
                        form={form}
                        inline
                        name={`colorsText.${index}`}
                        component={ColorPicker}
                      />
                    </ColorsInputItem>
                  );
                })}
              </ColorsInputContent>
            )}
          />
        </div>
        <div className="action-box" style={{ textAlign: 'right', marginTop: 10 }}>
          <Button size="large" htmlType="submit" type="primary" onClick={handleSubmit}>
            Lưu
          </Button>
        </div>
      </Form>
    );
  };

  useEffect(() => {
    dispatch(createAction(COLORS_REQUEST));
  }, [dispatch]);

  return (
    <Container>
      {isFetching && <Spinner />}
      {isFetched && (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            colors,
            colorsText,
          }}
          // validationSchema={validationSchema}
          onSubmit={handleSave}
          component={renderForm}
        />
      )}
    </Container>
  );
};

export default connect(({ colors }) => ({
  colorsStore: colors,
}))(withHeader(Colors));
