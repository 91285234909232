// import React from 'react';
import { put, call, takeLatest, fork } from 'redux-saga/effects';
import { callApi, createReducer, createAction } from 'dorothy/utils';
import { isEmpty } from 'lodash';
// import reactStringReplace from 'react-string-replace';

export const DASHBOARD_MESSAGES_REQUEST = 'DASHBOARD_MESSAGES_REQUEST';
export const DASHBOARD_MESSAGES_RESPONSE = 'DASHBOARD_MESSAGES_RESPONSE';
export const DASHBOARD_MESSAGES_DELETE = 'DASHBOARD_MESSAGES_DELETE';
export const DASHBOARD_MESSAGES_DELETE_SUCCESS = 'DASHBOARD_MESSAGES_DELETE_SUCCESS';

const initState = {
  messages: [],
  pagination: { currentPage: 1 },
};

// const messageFomart = messages => {
//   return messages.map(item => ({
//     ...item,
//     messageRaw: item.message,
//     message: reactStringReplace(item.message, /\[(.*?)\]/g, (rawText, processed, key) => (
//       <span className="metion" key={key}>
//         {rawText}
//       </span>
//     )),
//   }));
// };

function* requestMessages(action) {
  try {
    const response = yield call(callApi, 'GET', `/api/messages/all`, action.payload);
    if (response) yield put(createAction(DASHBOARD_MESSAGES_RESPONSE, response));
  } catch (error) {
    // console.log(error);
  }
}

function* requestMessagesWatch() {
  yield takeLatest(DASHBOARD_MESSAGES_REQUEST, requestMessages);
}

function* requestDeleteMessage(action) {
  try {
    const { id } = action.payload;
    callApi('DELETE', `/api/messages/${id}`, action.payload);
    yield put(createAction(DASHBOARD_MESSAGES_DELETE_SUCCESS, id));
  } catch (error) {
    // console.log(error);
  }
}

function* requestDeleteMessageWatch() {
  yield takeLatest(DASHBOARD_MESSAGES_DELETE, requestDeleteMessage);
}

const actionHandlers = {
  [DASHBOARD_MESSAGES_REQUEST]: (state, action) => {
    return {
      ...state,
      messagesLoading: true,
    };
  },
  [DASHBOARD_MESSAGES_RESPONSE]: (state, action) => {
    const { messages } = state;
    const { data, pagination = {} } = action.payload;
    const messagesLoadmore = !isEmpty(data);

    return {
      ...state,
      messagesLoading: false,
      messagesLoadmore,
      messages: data,
      pagination,
    };
  },
  [DASHBOARD_MESSAGES_DELETE_SUCCESS]: (state, action) => {
    const { messages } = state;
    const newMessages = messages.filter(item => item.id !== action.payload);
    return {
      ...state,
      messages: [...newMessages],
    };
  },
};

export const messagesReducer = createReducer(initState, actionHandlers);
export const messagesSagas = [fork(requestMessagesWatch), fork(requestDeleteMessageWatch)];

export default { messagesReducer, messagesSagas };
