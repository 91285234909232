import * as yup from 'yup';
import gPhoneNumber from 'google-libphonenumber';

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance();
const passwordRegex = /^.{6,150}$/;
const usernameRegex = /^[A-Za-z0-9]{6,}$/;
const ipRegex =
  /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/gi;

export const validation = {
  username: yup
    .string()
    .required('Đây là một trường bắt buộc')
    .matches(usernameRegex, 'Nhập ít nhất 6 ký tự và không bao gồm ký tự đặt biệt')
    .nullable(),
  ip: yup
    .string()
    .required('Đây là một trường bắt buộc')
    .matches(ipRegex, 'Địa chỉ IP không hợp lệ')
    .nullable(),
  required: yup.string().required('Đây là một trường bắt buộc').nullable(),
  number: yup
    .number()
    .transform((curr, orig) => (typeof orig === 'undefined' ? null : curr))
    .typeError('Vui lòng nhập số')
    .min(1, 'Vui lòng nhập lớn hơn 0')
    .max(9999999999, 'Vui lòng nhập nhỏ hơn 9999999999')
    .nullable(),
  password: yup
    .string()
    .required('Đây là một trường bắt buộc')
    .matches(passwordRegex, 'Nhập ít nhất 6 ký tự')
    .nullable(),
  passwordConfirmation: yup
    .string()
    .required('Đây là một trường bắt buộc')
    .oneOf([yup.ref('password'), null], 'Mật khẩu không khớp'),
  phoneNumberRequired: yup
    .string()
    .required('Đây là một trường bắt buộc')
    .test('phoneNumber', 'Số điện thoại không hợp lệ', function (value) {
      try {
        const regionCode = 'VN';
        const phoneNumber = phoneUtil.parseAndKeepRawInput(value, regionCode);
        return phoneUtil.isValidNumberForRegion(phoneNumber, regionCode);
      } catch (error) {
        return false;
      }
    })
    .nullable(),
};
