import React, { forwardRef, useState, useRef, useImperativeHandle, useCallback } from 'react';
import { MessageInput as MessageInputUI } from '@chatscope/chat-ui-kit-react';
import styled from 'styled-components';
import { Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import EmojiPicker, { Categories } from 'emoji-picker-react';

import ModalUpdateProfile from '../ModalUpdateProfile';

import { truncateString, pxToRem } from 'utils/common';
import { createAction } from 'dorothy/utils';
import { GET_ME_REQUEST } from 'components/dashboard/Users/ducks';

const EmojiPickerStyled = styled.div`
  .epr-header {
    display: none;
  }
`;

const MessageInputStyled = styled(MessageInputUI)`
  background: transparent;
  flex: 1;
  .cs-message-input__content-editor-wrapper,
  .cs-message-input__content-editor {
    background: #f6f6f6;
    color: #969696;
  }
  .cs-message-input__content-editor[data-placeholder]:empty:before {
    color: #969696;
  }
  .cs-button--send {
    color: #009b3a;
  }
`;

const MessageInputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 6px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px -1px 8px;
  border-top: 1px solid rgb(0 0 0 / 24%);
  > div:first-child {
    flex: 1;
  }
`;

const Icon = styled.i`
  font-size: ${pxToRem(18)};
  cursor: pointer;
`;

const MessageInput = forwardRef((props, ref) => {
  const { onSend, onProfile, max = 128, ...otherProps } = props;
  const [value, setValue] = useState('');
  const [showUpdateProfileModal, setShowUpdateProfileModal] = useState(false);
  const [open, setOpen] = useState(false);
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;
  const dispatch = useDispatch();
  const { userStore } = useSelector(({ user }) => ({
    userStore: user,
  }));
  const { user = {} } = userStore || {};

  const inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  const handleChange = (innerHtml, textContent) => {
    setValue(truncateString(textContent, max));
  };

  const handleSend = message => {
    setValue('');
    onSend && onSend(truncateString(message, max));
  };

  const handleOpenChange = newOpen => {
    setOpen(newOpen);
  };

  const handleEmojiClick = emoji => {
    setValue(`${value} ${emoji.emoji}`);
  };

  const handleShowProfile = () => {
    setShowUpdateProfileModal(true);
    dispatch(createAction(GET_ME_REQUEST, { username: user?.username, update: Date.now() }));
  };

  const popout = () => {
    window.open(
      window.location.href,
      null,
      'height=600,width=400,status=yes,toolbar=no,menubar=no,location=no',
    );
  };

  useImperativeHandle(resolvedRef, () => ({
    value,
    setValue,
  }));

  const closeUpdateProfileModal = useCallback(() => setShowUpdateProfileModal(false), []);

  return (
    <>
      <MessageInputWrapper className="message-input-wrapper">
        <Popover
          overlayClassName="popover-emoji"
          content={
            <EmojiPickerStyled>
              <EmojiPicker
                onEmojiClick={handleEmojiClick}
                autoFocusSearch={false}
                previewConfig={{
                  showPreview: false,
                }}
                categories={[
                  {
                    name: 'Smiles & Emotions',
                    category: Categories.SMILEYS_PEOPLE,
                  },
                ]}
                width="250"
                height="150"
              />
            </EmojiPickerStyled>
          }
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
          placement="topLeft"
        >
          <Icon
            className="fa-solid fa-face-smile"
            style={{
              marginRight: '10px',
            }}
          />
        </Popover>
        <MessageInputStyled
          onChange={handleChange}
          value={value}
          onSend={handleSend}
          sendDisabled={!value}
          {...otherProps}
        />
        <Icon className="fa-solid fa-user" onClick={handleShowProfile} />
        {inIframe() ? (
          <Icon
            className="fa-solid fa-arrow-up-right-from-square"
            onClick={popout}
            style={{ marginLeft: 12 }}
          />
        ) : (
          <></>
        )}
      </MessageInputWrapper>
      {showUpdateProfileModal && (
        <ModalUpdateProfile
          visible={showUpdateProfileModal}
          title={'Quản lý tài khoản'}
          onCancel={closeUpdateProfileModal}
        />
      )}
    </>
  );
});

export default MessageInput;
