import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import Table from 'antd-components/table';
import Button from 'antd-components/button';
import Popconfirm from 'antd-components/popConfirm';
import { createAction } from 'dorothy/utils/index';
import withHeader from '../withHeader';
import ActionsBox from '../ActionsBox';
import { IP_LIST_REQUEST, IP_ADD_REQUEST, IP_DELETE_REQUEST } from './ducks';
import Notification from 'antd-components/notification';
import ModalIpForm from './ModalIpForm';
import PaginationBox from 'antd-components/pagination';

// import { REACT_APP_BASE_URL } from 'constant/common';
// import { format } from 'date-fns';
import { formatDate } from 'dorothy/utils/formatDate';

const Container = styled.div`
  padding: 50px 100px;

  .table-box {
    margin-top: 30px;
  }
`;

const IpAddresses = ({ ipAddressStore, dispatch }) => {
  const [modalData, setModalData] = useState({ isOpen: false, editingId: '' });

  const { isOpen, editingId } = modalData;

  const { ipList, isFetching, pagination } = ipAddressStore;

  const { total } = pagination;

  const [filterOptions, setFilterOptions] = useState({ pageSize: 50, page: 1 });

  useEffect(() => {
    dispatch(createAction(IP_LIST_REQUEST, filterOptions));
  }, [dispatch, filterOptions]);

  const handleCloseModal = () => {
    setModalData({ isOpen: false, editingId: '' });
  };

  const onSubmit = values => {
    // console.log('values: ', values);

    dispatch(
      createAction(IP_ADD_REQUEST, {
        ...values,
        callBack: success => {
          if (success) {
            Notification.success('Thêm thành công!');
            setFilterOptions({ ...filterOptions });
            setModalData({ isOpen: false, editingId: '' });
          }
        },
      }),
    );
  };

  const columns = [
    {
      title: '#',
      key: '_id',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'Địa chỉ Ip',
      dataIndex: 'ip',
      key: 'ip',
    },
    {
      title: 'Cấm nhắn tin đến',
      dataIndex: 'deactive',
      key: 'deactive',
      render: (deactive, record, index) => <span>{formatDate(deactive)}</span>,
    },
    {
      title: 'Lý do',
      dataIndex: 'reason',
      key: 'reason',
    },
    {
      title: 'Chức năng',
      render: (name, record) =>
        record.role !== 'ADMIN' && (
          <>
            <Popconfirm
              placement="topRight"
              title="Bạn có chắc muốn xoá?"
              onConfirm={() => dispatch(createAction(IP_DELETE_REQUEST, record._id))}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link">Xoá</Button>
            </Popconfirm>
          </>
        ),
    },
  ];

  const handleSearch = searchValue => setFilterOptions({ ...filterOptions, page: 1, searchValue });

  return (
    <Container>
      {(isOpen || editingId !== '') && (
        <ModalIpForm
          onSubmit={onSubmit}
          visible={isOpen || editingId !== ''}
          title={editingId ? 'Cập nhật ban IP' : 'Tạo mới ban IP'}
          onCancel={handleCloseModal}
          editingId={editingId}
        />
      )}
      <ActionsBox
        title="Danh sách ban IP"
        buttonLabel="Tạo mới"
        onSearch={handleSearch}
        onClick={() => setModalData({ isOpen: true, editingId: '' })}
      />
      <div className="table-box">
        <Table
          bordered
          rowKey={(row, index) => index}
          dataSource={ipList}
          columns={columns}
          loading={isFetching}
        />
        <PaginationBox
          pageSize={Number(filterOptions.pageSize)}
          total={total}
          current={filterOptions.page}
          onChange={page => {
            setFilterOptions({ ...filterOptions, page });
          }}
        />
      </div>
    </Container>
  );
};

export default connect(({ ipAddress }) => ({
  ipAddressStore: ipAddress,
}))(withHeader(IpAddresses));
