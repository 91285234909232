import {
  colors as colorsCon,
  colorsText as colorsTextCon,
  colorsFade as colorsFadeCon,
} from 'constant/colors';
let colors = colorsCon;
let colorsText = colorsTextCon;
let colorsFade = colorsFadeCon;
if (typeof window.colorsCommon !== 'undefined') {
  colors = [...window.colorsCommon];
}
if (typeof window.colorsTextCommon !== 'undefined') {
  colorsText = [...window.colorsTextCommon];
}

export const useMessage = data => {
  if (!data) return {};
  const { id, idTmp, message, message_type, sender, user: userMessage } = data;
  const {
    username,
    role,
    fullname,
    avatar,
    socialAvatar,
    id: userId,
    level = 1,
    ip,
  } = userMessage || {};
  const isMessageSystem = sender === 'SYSTEM';
  const isMessageAdmin = role === 'ADMIN';
  const isMessageMod = role === 'MOD';
  const isMessageManager = role === 'ADMIN' || role === 'MOD';
  const name = isMessageSystem ? 'System' : fullname || username;
  const userLevel = level > 599 ? 599 : level;

  return {
    id,
    idTmp,
    username,
    fullname,
    userId,
    message,
    sender: name,
    avatar,
    socialAvatar,
    role,
    ip,
    type: message_type,
    isMessageSystem,
    isMessageAdmin,
    isMessageMod,
    isMessageManager,
    color: colors[userLevel - 1],
    colorText: colorsText[userLevel - 1],
    colorFade: colorsFade[userLevel - 1],
    level: userLevel,
  };
};
