import React, { useState } from 'react';
import styled from 'styled-components';
import { ChromePicker } from 'react-color';
import { Popover } from 'antd';
const ColorInput = styled.div`
  width: 50px;
  height: 20px;
  background: ${props => (props.background ? props.background : 'transparent')};
`;
const StyledInput = styled.div`
  /* stylelint-disable */
  padding: 2px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 3px;
`;

export default ({ field, form, modern, simple, value, className, ...props }) => {
  const [background, setBackground] = useState(field?.value || '#F47373');
  const [open, setOpen] = useState(false);

  const handleOpenChange = newOpen => {
    setOpen(newOpen);
  };

  const handleChange = value => {
    setBackground(value.hex);
    field.onChange({ target: { value: value.hex, name: field.name } });
  };

  return (
    <Popover
      content={<ChromePicker color={background} onChangeComplete={handleChange} />}
      open={open}
      trigger="click"
      onOpenChange={handleOpenChange}
      overlayClassName="popover-color-picker"
    >
      <StyledInput>
        <ColorInput background={background}></ColorInput>
      </StyledInput>
    </Popover>
  );
};
