import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import Linkify from 'react-linkify';

import { truncateString, pxToRem } from 'utils/common';
import ModalLogin from 'components/storefront/ModalLogin';
import Notification from 'antd-components/notification';
import { MESSAGES_DELETE } from 'components/storefront/Room/ducks';

import MessageInput from './MessageInput';
import MessageList from './MessageList';
import { useUser } from 'hooks';
import ModalBan from './ModalBan';
import { getAvatar, senderConvert } from 'utils/common';
import SocialLinks from './SocialLinks';

const OnlineLogin = styled.div`
  text-align: right;
  padding: 5px;
  background: #3a3d44;
  display: flex;
  align-items: center;
  font-size: ${pxToRem(12)};
`;

const Image = styled.img`
  width: 100%;
`;

const RequireLogin = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: #3a3d44;
  background: linear-gradient(
    0deg,
    rgba(58, 61, 68, 0.95) 0%,
    rgba(58, 61, 68, 0.5) 65%,
    rgba(58, 61, 68, 0) 100%
  );
  color: #fff;
  text-align: center;
  padding: 30px;
  font-weight: 600;
  font-size: ${pxToRem(20)};
  text-transform: uppercase;
  cursor: pointer;
`;

const RequireLoginDesc = styled.div`
  font-weight: 400;
  font-size: ${pxToRem(16)};
  text-transform: lowercase;
  color: #fff;
`;

const ChatBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`;

const ChatBoxDesc = styled.div`
  padding: 6px;
  text-align: center;
  background: #faca39;
  color: #333;
  font-weight: bold;
  white-space: pre-line;
  font-size: ${pxToRem(12)};
`;

const ImageCache = styled.img`
  width: 2px;
  height: 2px;
  display: none;
`;

const ChatBox = props => {
  const { roomId, room, messages = [], user = {}, onSend, onLoadMore, isLoading } = props;
  const { limitMessageLength = 128, limitSendSpeed = 5 } = room.settings || {};

  const [showLogin, setShowLogin] = useState(false);
  const { isManager } = useUser(user);
  const [showBanModal, setShowBanModal] = useState(false);
  const [banUser, setBanUserId] = useState();
  const dispatch = useDispatch();

  const sendCountRef = useRef(0);
  const messageInputRef = useRef();
  const [showChat, setShowChat] = useState(true);
  const [onlineUsers, setOnlineUsers] = useState(0);

  useEffect(() => {
    const fetchOnlineUsers = async () => {
      try {
        const response = await axios.get('https://api.chatboxn.com/api/room/online');
        setOnlineUsers(response.data?.online);
      } catch (error) {
        console.error('Failed to fetch online users:', error);
      }
    };

    fetchOnlineUsers();
    const interval = setInterval(fetchOnlineUsers, 5000); // Fetch every 5 seconds
    return () => clearInterval(interval);
  }, []);

  const handleSend = message => {
    let mess = message || '';
    if (!isManager) {
      if (sendCountRef.current) {
        Notification.sendMessageError(`Cảnh báo : Bạn sẽ bị ban nếu tiếp tục spam`);
        sendCountRef.current = sendCountRef.current + 1;
        return;
      }

      setTimeout(() => {
        sendCountRef.current = 0;
      }, limitSendSpeed * 1000);

      sendCountRef.current = sendCountRef.current + 1;
    }
    // const { token } = user;

    // if (!isManager) {
    //   mess = removeDomain(mess);
    // }

    onSend &&
      onSend({
        roomId,
        message: truncateString(mess, limitMessageLength),
      });
  };

  const handleLoadmore = () => {
    if (isLoading === true) {
      return;
    }

    onLoadMore && onLoadMore();
  };

  const handleMessageClick = message => {
    try {
      const { value, setValue } = messageInputRef.current || {};
      const { user } = message;
      const name = senderConvert(user.fullname || user.username);
      setValue(`${value} [${name}] `);
    } catch (error) {}
  };

  const handleShowbanModal = banUser => {
    setShowBanModal(true);
    setBanUserId(banUser);
  };

  const handleShowDeleteModal = message => {
    const messageDel = messages.find(item => item.id === message.id);
    dispatch({
      type: MESSAGES_DELETE,
      payload: {
        id: messageDel.id,
        roomId: messageDel.roomId,
        dataRaw: messageDel.dataRaw,
      },
    });
  };

  const closeModalLogin = useCallback(() => setShowLogin(false), []);
  const closeModalBan = useCallback(() => setShowBanModal(false), []);
  const renderImageCache = user => {
    if (!user) return;
    const avatar = getAvatar(user);
    return avatar ? <ImageCache src={avatar} /> : <></>;
  };
  return (
    <ChatBoxWrapper>
      <OnlineLogin>
        <SocialLinks showChat={showChat} setShowChat={setShowChat} />
        <div
          className="d-flex"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}
        >
          {onlineUsers && (
            <>
              <i className="fa-solid fa-user mr-1"></i>
              {onlineUsers}
            </>
          )}
        </div>
      </OnlineLogin>
      {showChat && (
        <MessageList
          user={user}
          messages={messages}
          onYReachStart={handleLoadmore}
          loadingMore={isLoading}
          onClickMessage={handleMessageClick}
          onShowBanModal={handleShowbanModal}
          onShowDeleteModal={handleShowDeleteModal}
        />
      )}
      {renderImageCache(user)}
      {room.isImage && room.image && showChat ? (
        room.link ? (
          <a href={room.link} target="_blank" rel="nofollow">
            <Image src={`${process.env.REACT_APP_IMG_URL}${room.image}`} />
          </a>
        ) : (
          <Image src={`${process.env.REACT_APP_IMG_URL}${room.image}`} />
        )
      ) : (
        <></>
      )}
      {!room.isImage && room.description ? (
        room.link ? (
          <a href={room.link} target="_blank" rel="nofollow">
            <ChatBoxDesc>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    className="message-linkify"
                    target="blank"
                    rel="nofollow"
                    href={decoratedHref}
                    key={key}
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {room?.description}
              </Linkify>
            </ChatBoxDesc>
          </a>
        ) : (
          <ChatBoxDesc>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a
                  className="message-linkify"
                  target="blank"
                  rel="nofollow"
                  href={decoratedHref}
                  key={key}
                >
                  {decoratedText}
                </a>
              )}
            >
              {room?.description}
            </Linkify>
          </ChatBoxDesc>
        )
      ) : (
        <></>
      )}
      {showChat &&
        (!isEmpty(user) ? (
          <MessageInput
            attachButton={false}
            onSend={handleSend}
            max={limitMessageLength}
            ref={messageInputRef}
          />
        ) : (
          <RequireLogin
            onClick={() => {
              setShowLogin(true);
              // popitup('https://www.w3schools.com');
            }}
          >
            Đăng Nhập
            <RequireLoginDesc>để tham gia bình luận</RequireLoginDesc>
          </RequireLogin>
        ))}
      <ModalLogin visible={showLogin} onCancel={closeModalLogin} roomId={roomId} />
      {showBanModal && (
        <ModalBan
          user={banUser}
          visible={showBanModal}
          title={'Cấm nhắn tin'}
          onCancel={closeModalBan}
        />
      )}
    </ChatBoxWrapper>
  );
};

export default ChatBox;
