import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Spinner from 'antd-components/spinner';

import Button from 'antd-components/button';
import withHeader from '../withHeader';

import { validation } from 'dorothy/utils/validation';
import Field from 'antd-components/field';
import Input, { TextAreaInput } from 'antd-components/input';
import { GET_SETTING_REQUEST, UPDATE_SETTING_REQUEST } from './ducks';
import Notification from 'antd-components/notification';

const Container = styled.div`
  padding: 50px 100px;
  .action-box {
    display: flex;
    justify-content: flex-end;
  }

  .form {
    max-width: 650px;

    .width-240 {
      width: 240px !important;
    }

    .field-group {
      p.label {
        font-size: 14px;
      }

      .field.inline {
        margin-bottom: 20px;
      }
    }
  }
`;

const Settings = ({ setting, isFetching, dispatch }) => {
  const validationSchema = yup.object().shape({
    limitMessageLength: validation.number,
    limitSendSpeed: validation.number,
    limitWarningSpam: validation.number,
  });

  const initialValues = {
    limitMessageLength: setting.limitMessageLength,
    limitSendSpeed: setting.limitSendSpeed,
    limitWarningSpam: setting.limitWarningSpam,
    messageSystem: setting.messageSystem,
    badWord: setting.badWord,
  };

  const renderForm = ({ handleSubmit, ...form }) => (
    <Form className="form">
      <div className="field-group">
        <Field
          form={form}
          inline
          name="limitMessageLength"
          label="Giới hạn dộ dài tin nhắn"
          component={Input}
          labelClasses="width-240"
        />
        <Field
          form={form}
          inline
          name="limitSendSpeed"
          label="Giới hạn tốc độ gởi tin nhắn"
          component={Input}
          labelClasses="width-240"
        />
        <Field
          form={form}
          inline
          name="limitWarningSpam"
          label="Giới hạn số lần spam tin nhắn"
          component={Input}
          labelClasses="width-240"
        />
        <Field
          form={form}
          inline
          name="messageSystem"
          label="Tin nhắn mặc định"
          component={TextAreaInput}
          rows="4"
          labelClasses="width-240"
        />
        <Field
          form={form}
          inline
          name="badWord"
          label="Từ tục tỉu"
          component={TextAreaInput}
          rows="50"
          labelClasses="width-240"
        />
      </div>
      <div className="action-box">
        <Button size="large" htmlType="submit" type="primary" onClick={handleSubmit}>
          Lưu
        </Button>
      </div>
    </Form>
  );

  const handleSaveSettings = data => {
    dispatch({
      type: UPDATE_SETTING_REQUEST,
      payload: {
        id: setting._id,
        ...data,
        callback: success => {
          if (success) {
            Notification.success('Cập nhật thành công');
          }
        },
      },
    });
  };

  useEffect(() => {
    dispatch({ type: GET_SETTING_REQUEST });
  }, [dispatch]);

  return (
    <Container>
      {isFetching ? (
        <Spinner />
      ) : (
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSaveSettings}
          component={renderForm}
        />
      )}
    </Container>
  );
};

export default connect(({ setting }) => ({
  setting: setting.setting,
  isFetching: setting.isFetching,
}))(withHeader(Settings));
