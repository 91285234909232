import { put, call, takeLatest, fork } from 'redux-saga/effects';
import { callApi, createReducer, createAction } from 'dorothy/utils';

export const COLORS_REQUEST = 'COLORS_REQUEST';
export const COLORS_RESPONSE = 'COLORS_RESPONSE';

export const COLORS_SAVE_REQUEST = 'COLORS_SAVE_REQUEST';

export const REQUEST_ERROR = 'REQUEST_ERROR';

function* requestColors(action) {
  try {
    const response = yield call(callApi, 'GET', `/api/colors`, action.payload);

    if (response) yield put(createAction(COLORS_RESPONSE, response));
  } catch (error) {
    console.log(error);
  }
}

function* watchColorsRequest() {
  yield takeLatest(COLORS_REQUEST, requestColors);
}

function* saveColor(action) {
  const { callBack, ...data } = action.payload;

  try {
    const response = yield call(callApi, 'POST', `/api/colors`, data);
    console.log('response: ', response);
    if (response && response.success) {
      callBack(true);
    }
  } catch (error) {
    // console.log(error);
    callBack(false);
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchSaveColor() {
  yield takeLatest(COLORS_SAVE_REQUEST, saveColor);
}

const initColor = {
  colors: [],
};
const colorActionHandlers = {
  [COLORS_REQUEST]: (state, action) => ({ ...state, isFetching: true }),
  [COLORS_RESPONSE]: (state, action) => {
    const { data = [], dataText = [] } = action.payload;
    return {
      ...state,
      isFetching: false,
      isFetched: true,
      colors: data,
      colorsText: dataText,
    };
  },
};

export const colorReducer = createReducer(initColor, colorActionHandlers);
export const colorSagas = [fork(watchColorsRequest), fork(watchSaveColor)];
