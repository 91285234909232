import { put, call, takeLatest, fork } from 'redux-saga/effects';
import { callApi, createReducer, createAction } from 'dorothy/utils';

export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_RESPONSE = 'USERS_RESPONSE';

export const LOGIN_DASHBOARD_REQUEST = 'LOGIN_DASHBOARD_REQUEST';
export const LOGIN_STOREFRONT_REQUEST = 'LOGIN_STOREFRONT_REQUEST';

export const USER_ADD_REQUEST = 'USER_ADD_REQUEST';
export const USER_ADD_RESPONSE = 'USER_ADD_RESPONSE';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_RESPONSE = 'USER_UPDATE_RESPONSE';

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST';
export const USER_DELETE_RESPONSE = 'USER_DELETE_RESPONSE';

export const LOGIN_FACEBOOK_REQUEST = 'LOGIN_FACEBOOK_REQUEST';
export const LOGIN_GOOGLE_REQUEST = 'LOGIN_GOOGLE_REQUEST';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_RESPONSE = 'UPDATE_PROFILE_RESPONSE';
export const UPDATE_AVATAR_REQUEST = 'UPDATE_AVATAR_REQUEST';
export const UPDATE_AVATAR_RESPONSE = 'UPDATE_AVATAR_RESPONSE';

export const SEND_CODE_REQUEST = 'SEND_CODE_REQUEST';

export const LOGIN_SMS_REQUEST = 'LOGIN_SMS_REQUEST';

export const GET_ME_REQUEST = 'GET_ME_REQUEST';
export const GET_ME_RESPONSE = 'GET_ME_RESPONSE';

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const BAN_USER_REQUEST = 'BAN_USER_REQUEST';

export const UPDATE_USER = 'UPDATE_USER';
export const REQUEST_ERROR = 'REQUEST_ERROR';

function* requestUser(action) {
  try {
    const response = yield call(callApi, 'GET', `/api/users`, action.payload);

    if (response) yield put(createAction(USERS_RESPONSE, response));
  } catch (error) {
    // console.log(error);
  }
}

function* watchUsersRequest() {
  yield takeLatest(USERS_REQUEST, requestUser);
}

function* addUser(action) {
  const { callBack, ...data } = action.payload;
  try {
    const response = yield call(callApi, 'POST', `/api/users`, data);

    if (response) {
      yield put(createAction(USER_ADD_RESPONSE, response));
      callBack(true);
    }
  } catch (error) {
    // console.log(error);
    callBack(false);
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchAddUser() {
  yield takeLatest(USER_ADD_REQUEST, addUser);
}

function* deleteUser(action) {
  try {
    const response = yield call(callApi, 'DELETE', `/api/users/${action.payload}`);

    if (response && response.success) yield put(createAction(USER_DELETE_RESPONSE, action.payload));
  } catch (error) {
    // console.log(error);
  }
}

function* watchDeleteUser() {
  yield takeLatest(USER_DELETE_REQUEST, deleteUser);
}

function* updateUser(action) {
  const { callBack, ...data } = action.payload;
  const { _id, ...payload } = data;

  try {
    const response = yield call(callApi, 'PATCH', `/api/users/${_id}`, payload);

    if (response && response.success) {
      yield put(createAction(USER_UPDATE_RESPONSE, { _id, updateData: payload }));
      callBack(true);
    }
  } catch (error) {
    // console.log(error);
    callBack(false);
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchUpdateUser() {
  yield takeLatest(USER_UPDATE_REQUEST, updateUser);
}

function* loginDashboard(action) {
  const { history, callBack, ...data } = action.payload;
  try {
    const response = yield call(callApi, 'POST', `/api/users/login`, data);

    if (response) {
      yield put(createAction(UPDATE_USER, response));
      history && history.push('/dashboard/users');
      callBack && callBack(true);
    }
  } catch (error) {
    // console.log(error);
    callBack && callBack(false);
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchLoginDashboard() {
  yield takeLatest(LOGIN_DASHBOARD_REQUEST, loginDashboard);
}

function* loginFacebook(action) {
  const { callBack, ...data } = action.payload;
  try {
    const response = yield call(callApi, 'POST', `/api/users/login-facebook`, data);

    if (response) {
      yield put(createAction(UPDATE_USER, response));
      callBack && callBack(true);
    }
  } catch (error) {
    // console.log(error);
    callBack && callBack(false);
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchLoginFacebook() {
  yield takeLatest(LOGIN_FACEBOOK_REQUEST, loginFacebook);
}

function* loginGoogle(action) {
  const { callBack, ...data } = action.payload;
  try {
    const response = yield call(callApi, 'POST', `/api/users/login-google`, data);

    if (response) {
      yield put(createAction(UPDATE_USER, response));
      callBack && callBack(true);
    }
  } catch (error) {
    // console.log(error);
    callBack && callBack(false);
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchLoginGoogle() {
  yield takeLatest(LOGIN_GOOGLE_REQUEST, loginGoogle);
}

function* updateProfile(action) {
  const { callBack, ...data } = action.payload;
  try {
    const response = yield call(callApi, 'PUT', `/api/users/update-profile`, data);
    if (response) {
      yield put(createAction(UPDATE_PROFILE_RESPONSE, response));
      callBack && callBack(true);
    }else {
      callBack && callBack(false);
      yield put(createAction(REQUEST_ERROR));
    }
  } catch (error) {
    callBack && callBack(false);
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchUpdateProfile() {
  yield takeLatest(UPDATE_PROFILE_REQUEST, updateProfile);
}

function* updateAvatar(action) {
  const { callBack, id, ...data } = action.payload;
  try {
    const formData = new FormData();

    formData.append('avatar', data.avatar);

    const response = yield call(callApi, 'PUT', `/api/users/update-avatar`, formData);

    if (response) {
      yield put(createAction(UPDATE_AVATAR_RESPONSE, response));
      callBack && callBack(response);
    }
  } catch (error) {
    // console.log(error);
    callBack && callBack(false);
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchUpdateAvatar() {
  yield takeLatest(UPDATE_AVATAR_REQUEST, updateAvatar);
}

function* loginStorefront(action) {
  const { history, callBack, ...data } = action.payload;
  try {
    const response = yield call(callApi, 'POST', `/api/users/login-storefront`, data);

    if (response) {
      yield put(createAction(UPDATE_USER, response));
      callBack && callBack(true);
    }
  } catch (error) {
    // console.log(error);
    callBack && callBack(false);
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchLoginStoreFront() {
  yield takeLatest(LOGIN_STOREFRONT_REQUEST, loginStorefront);
}

function* sendSmsCode(action) {
  const { history, callBack, ...data } = action.payload;
  try {
    const response = yield call(callApi, 'POST', `/api/users/send-sms-code`, data);

    if (response) {
      callBack && callBack(true);
    }
  } catch (error) {
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchSendSmsCode() {
  yield takeLatest(SEND_CODE_REQUEST, sendSmsCode);
}

function* loginSms(action) {
  const { callBack, ...data } = action.payload;
  try {
    const response = yield call(callApi, 'POST', `/api/users/login-sms`, data);

    if (response) {
      yield put(createAction(UPDATE_USER, response));
      callBack && callBack(true);
    }
  } catch (error) {
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchLoginSms() {
  yield takeLatest(LOGIN_SMS_REQUEST, loginSms);
}

function* getMe(action) {
  const { ...data } = action.payload;
  try {
    const response = yield call(callApi, 'GET', `/api/users/me`, { ...data });

    if (response) {
      yield put(createAction(GET_ME_RESPONSE, response));
    }
  } catch (error) {
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchGetMe() {
  yield takeLatest(GET_ME_REQUEST, getMe);
}

function* registerUser(action) {
  const { callBack, ...data } = action.payload;

  try {
    const response = yield call(callApi, 'POST', `/api/users/register`, data);

    if (response) {
      yield put(createAction(UPDATE_USER, response));
      callBack && callBack(true);
    }
  } catch (error) {
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchRegisterUser() {
  yield takeLatest(REGISTER_USER_REQUEST, registerUser);
}

function* banUser(action) {
  const { callBack, username, ip, deactive } = action.payload;

  try {
    const response = yield call(callApi, 'POST', `/api/users/${username}/ban`, { deactive });
    if (ip) {
      yield call(callApi, 'POST', `/api/ban-ip`, {
        ip,
        deactive,
        reason: `Ban user ${username}`,
      });
    }

    if (response) {
      callBack && callBack(true);
    }
  } catch (error) {
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchBanUser() {
  yield takeLatest(BAN_USER_REQUEST, banUser);
}

const initUser = {
  isFetchingUsers: false,
  users: [],
  user: {},
  pagination: { currentPage: 1 },
};
const userActionHandlers = {
  [USERS_REQUEST]: (state, action) => ({ ...state, isFetchingUsers: true }),
  [USERS_RESPONSE]: (state, action) => {
    const { data, pagination } = action.payload;
    return {
      ...state,
      isFetchingUsers: false,
      users: data,
      pagination,
    };
  },
  [USER_ADD_REQUEST]: (state, action) => ({ ...state, isWaitingAdd: true }),
  [USER_ADD_RESPONSE]: (state, action) => {
    const users = [...state.users, action.payload];
    return {
      ...state,
      isWaitingAdd: false,
      users,
    };
  },
  [USER_UPDATE_REQUEST]: (state, action) => ({ ...state, isWaitingUpdate: true }),
  [USER_UPDATE_RESPONSE]: (state, action) => {
    const users = state.users.map(employee =>
      employee._id === action.payload._id
        ? { ...employee, ...action.payload.updateData }
        : employee,
    );
    return {
      ...state,
      isWaitingUpdate: false,
      users,
    };
  },
  [USER_DELETE_RESPONSE]: (state, action) => {
    const users = state.users.filter(({ username }) => username !== action.payload);
    return {
      ...state,
      isWaitingAdd: false,
      users,
    };
  },
  [UPDATE_USER]: (state, action) => {
    const user = action.payload;
    localStorage.setItem('user', JSON.stringify(user));
    return {
      ...state,
      user,
    };
  },
  [GET_ME_RESPONSE]: (state, action) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const newUser = { ...action.payload, token: user.token };
    localStorage.setItem('user', JSON.stringify(newUser));
    return {
      ...state,
      user: newUser,
    };
  },
  [UPDATE_PROFILE_REQUEST]: (state, action) => {
    return {
      ...state,
      isUpdatingProfile: true,
    };
  },
  [UPDATE_AVATAR_RESPONSE]: (state, action) => {
    const user = { ...state.user, avatar: action.payload.avatar };
    localStorage.setItem('user', JSON.stringify(user));
    return {
      ...state,
      user,
    };
  },
  [UPDATE_PROFILE_RESPONSE]: (state, action) => {
    // console.log('action.payload UPDATE_PROFILE_RESPONSE: ', action.payload);
    const user = { ...state.user, ...action.payload };
    localStorage.setItem('user', JSON.stringify(user));
    return {
      ...state,
      user,
      isUpdatingProfile: false,
    };
  },
  [REQUEST_ERROR]: (state, action) => {
    return {
      ...state,
      isUpdatingProfile: false,
    };
  },
};

export const userReducer = createReducer(initUser, userActionHandlers);
export const userSagas = [
  fork(watchUsersRequest),
  fork(watchAddUser),
  fork(watchDeleteUser),
  fork(watchUpdateUser),
  fork(watchLoginDashboard),
  fork(watchLoginFacebook),
  fork(watchLoginGoogle),
  fork(watchUpdateProfile),
  fork(watchLoginStoreFront),
  fork(watchSendSmsCode),
  fork(watchLoginSms),
  fork(watchGetMe),
  fork(watchUpdateAvatar),
  fork(watchRegisterUser),
  fork(watchBanUser),
];
