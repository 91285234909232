import axios from 'axios';
import Notification from 'antd-components/notification';

const defaultTimeout = 10000;

const callApi = (method, url, data = {}) => {
  const user = JSON.parse(localStorage.getItem('user'));

  const config = {
    method,
    url: process.env.REACT_APP_API_URL + url,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${user?.token}`,
    },
    timeout: data.timeout || defaultTimeout,
    data,
    ...(method === 'GET' && { params: data }),
  };

  axios.interceptors.response.use(
    response => response,
    error => {
      const errorMessage = error?.response?.data?.errorMessage;
      if (errorMessage) {
        Notification.error(errorMessage);
      }
      Promise.reject(error?.response);
      if (error.response.status === 401) {
        localStorage.removeItem('user');
        // window.location.href = '/dashboard/login';
      }
    },
  );

  return axios(config).then((response, error) => {
    if (error) return error;

    return response?.data;
  });
};

export default callApi;
