import React from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Switch } from 'antd';

import Button from 'antd-components/button';
import Field from 'antd-components/field';
import Input from 'antd-components/input';
import { TextAreaInput } from 'antd-components/input';
import Modal from 'antd-components/modal';
import { validation } from 'dorothy/utils/validation';

const StyledForm = styled.div`
  .action-box {
    display: flex;
    justify-content: flex-end;

    > button:nth-child(2) {
      margin-left: 20px;
    }
  }
`;

const ImageWrapper = styled.div`
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  img {
    max-width: 100%;
  }
  .clear {
    cursor: pointer;
    margin-left: 3px;
    color: #40a9ff;
  }
`;

const InputFile = ({ field, form, modern, simple, value, image, ...props }) => {
  const handleChange = e => {
    const val = e.target.files[0];
    field.onChange({ target: { value: val, name: field.name } });
  };
  const handleClear = e => {
    field.onChange({ target: { value: '', name: field.name } });
  };
  return (
    <>
      {typeof image === 'string' && image && (
        <ImageWrapper>
          <div>
            <img src={`${process.env.REACT_APP_IMG_URL}${image}`} />
          </div>
          <span className="clear" onClick={handleClear}>
            xóa
          </span>
        </ImageWrapper>
      )}
      <input {...props} onChange={e => handleChange(e)} />
    </>
  );
};

const InputSwitch = ({ field, form, modern, simple, value, ...props }) => {
  const handleChange = checked => {
    field.onChange({ target: { value: checked, name: field.name } });
  };
  return <Switch {...props} onChange={e => handleChange(e)} />;
};

export default function ModalRoomForm(props) {
  const { onSubmit, visible, title, onCancel, editingId, initialValues } = props;

  const validationSchema = yup.object().shape({
    name: validation.required,
  });

  const renderForm = ({ handleSubmit, ...form }) => {
    const { values } = form;
    return (
      <Form className="form">
        <div className="field-group">
          <Field form={form} inline name="name" label="Tên" component={Input} />
          <Field
            form={form}
            inline
            name="isImage"
            label="Stick Image"
            component={InputSwitch}
            checked={values.isImage}
          />
          <Field
            form={form}
            inline
            name="description"
            label="Nội dung"
            rows={4}
            component={TextAreaInput}
          />
          <Field
            form={form}
            inline
            name="image"
            label="Hình ảnh"
            component={InputFile}
            type="file"
            image={values.image}
          />
          <Field form={form} inline name="link" label="Đường dẫn" component={Input} />
        </div>
        <div className="action-box">
          <Button size="large" htmlType="submit" type="primary" onClick={handleSubmit}>
            Lưu
          </Button>
          <Button size="large" onClick={onCancel}>
            Huỷ bỏ
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <Modal title={title} visible={visible} onCancel={onCancel} className="admin-side">
      <StyledForm>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          component={renderForm}
        />
      </StyledForm>
    </Modal>
  );
}
