import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import Button from 'antd-components/button';
import Field from 'antd-components/field';
import Input from 'antd-components/input';
import Modal from 'antd-components/modal';
import { SEND_CODE_REQUEST, LOGIN_SMS_REQUEST } from 'components/dashboard/Users/ducks';
import { useDispatch, connect } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { pxToRem } from 'utils/common';
import { validation } from 'dorothy/utils/validation';

const StyledForm = styled.div`
  .action-box {
    display: flex;
    justify-content: flex-end;

    > button:nth-child(2) {
      margin-left: 10px;
    }
  }

  .confirm-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;

    button {
      margin-bottom: 2px;
    }
  }

  .PhoneInputInput {
    border: none;
    outline: none;
  }

  .field-group {
    .label {
      margin-bottom: ${pxToRem(5)};
    }
    margin-bottom: 15px;
  }
`;

const ReCaptchaWrapper = styled.div`
  height: 70px;
  position: relative;
  width: 100%;
  .re-captcha-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    > div {
      transform: scale(0.73);
    }
  }
`;

const ModalLoginPhone = props => {
  const { visible, title, onCancel, onSuccess, roomId } = props;
  const dispatch = useDispatch();
  const [isShowConfirmCode, setShowConfirmCode] = useState(false);

  const validationSchema = yup.object().shape({
    phoneNumber: validation.phoneNumberRequired,
    fullname: validation.required,
    password: validation.password,
    confirmPass: validation.passwordConfirmation,
  });

  // const handleChangePhoneNumber = (phoneNumber, setFieldValue) => {
  //   setFieldValue('phoneNumber', phoneNumber);
  // };

  // const handleSendCode = values => {
  //   dispatch({
  //     type: SEND_CODE_REQUEST,
  //     payload: {
  //       phoneNumber: values.phoneNumber,
  //       captchaToken: values.captchaToken,
  //       callBack: success => {
  //         if (success) {
  //           setShowConfirmCode(true);
  //         }
  //       },
  //     },
  //   });
  // };

  const handleLoginPhone = data => {
    if (isShowConfirmCode) {
      dispatch({
        type: LOGIN_SMS_REQUEST,
        payload: {
          ...data,
          roomId,
          callBack: success => {
            if (success) {
              onCancel();
              onSuccess();
            }
          },
        },
      });
    } else {
      dispatch({
        type: SEND_CODE_REQUEST,
        payload: {
          ...data,
          roomId,
          callBack: success => {
            if (success) {
              setShowConfirmCode(true);
            }
          },
        },
      });
    }
  };

  const handleCapchaChange = useCallback((token, setFieldValue) => {
    setFieldValue('captchaToken', token);
  }, []);

  const renderForm = ({ handleSubmit, setFieldValue, values, validateForm, ...form }) => {
    return (
      <Form className="form">
        {isShowConfirmCode ? (
          <>
            <div className="field-group">
              <Field form={form} name="code" label="Mã xác nhận" component={Input} />
            </div>

            <div className="action-box">
              <Button
                size="small"
                onClick={() => {
                  setShowConfirmCode(false);
                  setFieldValue('captchaToken', null);
                }}
              >
                Quay lại
              </Button>
              <Button size="small" htmlType="submit" type="primary" onClick={handleSubmit}>
                Đăng nhập
              </Button>
            </div>
          </>
        ) : (
          <div className="field-group">
            <div className="field-group">
              <Field form={form} name="phoneNumber" label="Số điện thoại" component={Input} />
            </div>
            <div className="field-group">
              <Field form={form} name="fullname" label="Biệt danh" component={Input} />
            </div>
            <div className="field-group">
              <Field
                form={form}
                name="password"
                label="Mật khẩu"
                type="password"
                component={Input}
              />
              <Field
                form={form}
                name="confirmPass"
                label="Nhập lại mật khẩu"
                type="password"
                component={Input}
              />
            </div>
            <div className="confirm-box">
              <ReCaptchaWrapper className="re-captcha-wrapper">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={token => handleCapchaChange(token, setFieldValue)}
                  className="re-captcha-box"
                />
              </ReCaptchaWrapper>
              <Button size="small" htmlType="submit" type="primary" disabled={!values.captchaToken}>
                Gửi mã xác nhận
              </Button>
            </div>
          </div>
        )}
      </Form>
    );
  };

  return (
    <Modal title={title} visible={visible} onCancel={onCancel}>
      <StyledForm>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{}}
          validationSchema={validationSchema}
          onSubmit={handleLoginPhone}
          component={renderForm}
        />
      </StyledForm>
    </Modal>
  );
};

export default connect(({ user }) => ({
  userStore: user,
}))(ModalLoginPhone);
