import React from 'react';
import { put, call, takeLatest, fork } from 'redux-saga/effects';
import { callApi, createReducer, createAction } from 'dorothy/utils';
import { isEmpty } from 'lodash';
import reactStringReplace from 'react-string-replace';
import { removeDomain } from 'utils/common';
import { useUser } from 'hooks';

export const MESSAGES_REQUEST = 'MESSAGES_REQUEST';
export const MESSAGES_RESPONSE = 'MESSAGES_RESPONSE';
export const MESSAGES_REQUEST_SUCCESS = 'MESSAGES_REQUEST_SUCCESS';
export const MESSAGES_DELETE = 'MESSAGES_DELETE';
export const MESSAGES_DELETE_SUCCESS = 'MESSAGES_DELETE_SUCCESS';
export const MESSAGES_NEW = 'MESSAGES_NEW';
export const MESSAGES_ACTIVE = 'MESSAGES_ACTIVE';
export const MESSAGES_NEW_CLEAN = 'MESSAGES_NEW_CLEAN';
export const ROOMS_DETAIL_REQUEST = 'ROOMS_DETAIL_REQUEST';
export const ROOMS_DETAIL_RESPONSE = 'ROOMS_DETAIL_RESPONSE';
export const ROOMS_ONLINE_RESPONSE = 'ROOMS_ONLINE_RESPONSE';
export const BAN_USER = 'BAN_USER';
export const MESSAGES_RESIZE = 'MESSAGES_RESIZE';
export const MESSAGES_MAX_LENGTH = 2000;

const initState = {
  messages: [],
  messagesLoading: false,
  messagesLoadmore: true,
  room: {},
  roomLoading: false,
};

const messageFomart = messages => {
  return messages.map(item => {
    const { isManager } = useUser(item.user);
    const messageRaw = item.messageRaw || item.message;
    let messageConvert = messageRaw;

    if (!isManager) {
      messageConvert = removeDomain(messageRaw);
    }

    return {
      ...item,
      messageRaw,
      message: reactStringReplace(messageConvert, /\[(.*?)\]/g, (rawText, processed, key) => (
        <span className="metion" key={key}>
          {rawText}
        </span>
      )),
    };
  });
};

function* requestMessages(action) {
  const { callBack, ...data } = action.payload;
  try {
    const response = yield call(callApi, 'GET', `/api/messages`, data);
    if (response) yield put(createAction(MESSAGES_RESPONSE, response));
    callBack && callBack(response);
  } catch (error) {
    callBack && callBack();
    // console.log(error);
  }
}

function* requestMessagesWatch() {
  yield takeLatest(MESSAGES_REQUEST, requestMessages);
}

function* requestRoomDetail(action) {
  try {
    const { roomId } = action.payload;
    const response = yield call(callApi, 'GET', `/api/rooms/${roomId}`, action.payload);
    if (response) yield put(createAction(ROOMS_DETAIL_RESPONSE, response));
  } catch (error) {
    // console.log(error);
  }
}

function* requestRoomDetailWatch() {
  yield takeLatest(ROOMS_DETAIL_REQUEST, requestRoomDetail);
}

function* requestBanUser(action) {
  try {
    const { id } = action.payload;
    const response = yield call(callApi, 'PATCH', `/api/users/${id}/ban`, action.payload);
    if (response) yield put(createAction(BAN_USER, response));
  } catch (error) {
    // console.log(error);
  }
}

function* requestBanUserWatch() {
  yield takeLatest(BAN_USER, requestBanUser);
}

function* requestDeleteMessage(action) {
  try {
    const { id } = action.payload;
    callApi('DELETE', `/api/messages/${id}`, action.payload);
    yield put(createAction(MESSAGES_DELETE_SUCCESS, id));
  } catch (error) {
    // console.log(error);
  }
}

function* requestDeleteMessageWatch() {
  yield takeLatest(MESSAGES_DELETE, requestDeleteMessage);
}

const actionHandlers = {
  [MESSAGES_REQUEST]: (state, action) => {
    return {
      ...state,
      messagesLoading: true,
    };
  },
  [MESSAGES_RESPONSE]: (state, action) => {
    const { messages } = state;
    const { data } = action.payload;
    const messagesLoadmore = !isEmpty(data);
    return {
      ...state,
      // messagesLoading: false,
      messagesLoadmore,
      messages: messageFomart([
        ...data.reverse().map(item => ({ ...item, dataRaw: JSON.stringify(item) })),
        ...messages,
      ]),
    };
  },
  [MESSAGES_REQUEST_SUCCESS]: (state, action) => {
    return {
      ...state,
      messagesLoading: false,
    };
  },
  [MESSAGES_DELETE_SUCCESS]: (state, action) => {
    const { messages } = state;
    const newMessages = messages.filter(item => item.id !== action.payload);
    return {
      ...state,
      messages: [...newMessages],
    };
  },
  [MESSAGES_NEW]: (state, action) => {
    const { messages, isNewMessages = 0 } = state;
    const { data } = action.payload;
    return {
      ...state,
      isNewMessages: isNewMessages + (data?.length || 1),
      messages: messageFomart([
        ...messages,
        ...data.map(item => ({ ...item, dataRaw: JSON.stringify(item) })),
      ]),
    };
  },
  [MESSAGES_RESIZE]: state => {
    const { messages } = state;
    const newMessages =
      messages.length > MESSAGES_MAX_LENGTH
        ? messages.slice(messages.length - 500, messages.length)
        : messages;
    return {
      ...state,
      messages: newMessages,
    };
  },
  [MESSAGES_NEW_CLEAN]: state => {
    return {
      ...state,
      isNewMessages: 0,
    };
  },
  [MESSAGES_ACTIVE]: (state, action) => {
    const { messages } = state;
    return {
      ...state,
      messages: messages.map(item => {
        if (item?.idTmp && item?.messageRaw === action?.payload?.message) {
          return { ...action?.payload, dataRaw: JSON.stringify(action?.payload) };
        }
        return item;
      }),
    };
  },

  [ROOMS_DETAIL_REQUEST]: (state, action) => {
    return {
      ...state,
      roomLoading: true,
    };
  },
  [ROOMS_DETAIL_RESPONSE]: (state, action) => {
    const { room } = state;
    const { data } = action.payload;
    return {
      ...state,
      roomLoading: false,
      room: { ...room, ...data },
    };
  },
  [ROOMS_ONLINE_RESPONSE]: (state, action) => {
    const { count, ...other } = action.payload;
    const { room } = state;
    return {
      ...state,
      room: {
        ...room,
        ...(count ? { online: count } : {}),
        debug: other,
      },
    };
  },
};

export const roomReducer = createReducer(initState, actionHandlers);
export const roomSagas = [
  fork(requestRoomDetailWatch),
  fork(requestMessagesWatch),
  fork(requestBanUserWatch),
  fork(requestDeleteMessageWatch),
];

export default { roomReducer, roomSagas };
