import { useEffect, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { isEmpty } from 'lodash';

import { callApi } from 'dorothy/utils';

const keyword = {
  onlineTimeCheck: 'ol_cookie',
};
const timeSetting = 5 * 60 * 1000;
let clearInterval;

export const useOnlineTime = (user, cookiesInstant) => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const setOnlineTime = useCallback(() => {
    setCookie(keyword.onlineTimeCheck, Date.now(), {
      expires: (() => new Date(+new Date() + timeSetting))(),
    });
  }, [setCookie]);

  useEffect(() => {
    if (!isEmpty(user)) {
      setOnlineTime();
      clearInterval = window.setInterval(async () => {
        const onlineTime = cookiesInstant?.get(keyword.onlineTimeCheck);
        if (!onlineTime) {
          await callApi('POST', `/api/users/olt`);
          setOnlineTime();
        }
      }, timeSetting);
    } else {
      window.clearInterval(clearInterval);
    }

    return () => {
      window.clearInterval(clearInterval);
    };
  }, [user, cookiesInstant, setOnlineTime]);
};
