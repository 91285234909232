import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import Table from 'antd-components/table';
import Button from 'antd-components/button';
import Popconfirm from 'antd-components/popConfirm';
import { callApi, createAction } from 'dorothy/utils/index';
import withHeader from '../withHeader';
import ActionsBox from '../ActionsBox';
import { ROOMS_REQUEST, ROOM_ADD_REQUEST, ROOM_DELETE_REQUEST, ROOM_UPDATE_REQUEST } from './ducks';
import Notification from 'antd-components/notification';
import ModalRoomForm from './ModalRoomForm';
import PaginationBox from 'antd-components/pagination';

import { REACT_APP_BASE_URL } from 'constant/common';

const Container = styled.div`
  padding: 50px 100px;

  .table-box {
    margin-top: 30px;
  }
`;

const ImageContainer = styled.div`
  img {
    max-width: 200px;
  }
`;

const Rooms = ({ roomStore, dispatch }) => {
  const [modalData, setModalData] = useState({ isOpen: false, editingId: '' });

  const { isOpen, editingId } = modalData;

  const { rooms, isFetching, pagination } = roomStore;

  const { total } = pagination;

  const [filterOptions, setFilterOptions] = useState({ pageSize: 50, page: 1 });

  useEffect(() => {
    dispatch(createAction(ROOMS_REQUEST, filterOptions));
  }, [dispatch, filterOptions]);

  const handleCloseModal = () => {
    setModalData({ isOpen: false, editingId: '' });
  };

  const onSubmit = async values => {
    const { image } = values;
    const payload = values;
    if (typeof image === 'object') {
      const formData = new FormData();
      formData.append('file', image);
      try {
        const uploadFile = await callApi('POST', '/api/uploads', formData);
        if (uploadFile && uploadFile.image) {
          payload.image = uploadFile.image;
        }
      } catch (e) {
        console.log(e.message);
        return;
      }
    }
    if (editingId !== '') {
      dispatch(
        createAction(ROOM_UPDATE_REQUEST, {
          ...payload,
          callBack: success => {
            if (success) {
              Notification.success('Cập nhật thành công!');
              setFilterOptions({ ...filterOptions });
              setModalData({ isOpen: false, editingId: '' });
            } else Notification.error('Cập nhật thất bại!');
          },
        }),
      );
    } else {
      dispatch(
        createAction(ROOM_ADD_REQUEST, {
          ...payload,
          callBack: success => {
            if (success) {
              Notification.success('Thêm thành công!');
              setFilterOptions({ ...filterOptions });
              setModalData({ isOpen: false, editingId: '' });
            } else Notification.error('Thêm thất bại!');
          },
        }),
      );
    }
  };

  const editingRoom = rooms.find(({ _id }) => _id === editingId) || {};
  const { name, description, link, image, isImage, _id } = editingRoom;

  const initialValues = {
    _id,
    name: name || '',
    description: description || '',
    link: link || '',
    image,
    isImage: !!isImage,
  };

  const columns = [
    {
      title: '#',
      key: '_id',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Đường dẫn',
      dataIndex: 'link',
      key: 'link',
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'image',
      key: 'image',
      render: record => {
        return record ? (
          <ImageContainer>
            <img src={`${process.env.REACT_APP_IMG_URL}${record}`} alt="room" />
          </ImageContainer>
        ) : (
          <></>
        );
      },
    },
    {
      title: 'Chức năng',
      render: (name, record) =>
        record.role !== 'ADMIN' && (
          <>
            <Button
              onClick={() => setModalData({ ...modalData, editingId: record._id })}
              type="primary"
              className="mr-2 mb-2"
            >
              Sửa
            </Button>
            <Popconfirm
              placement="topRight"
              title="Bạn có chắc muốn xoá?"
              onConfirm={() => dispatch(createAction(ROOM_DELETE_REQUEST, record._id))}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger" className="mr-2 mb-2">
                Xoá
              </Button>
            </Popconfirm>
            <a
              className="ant-btn ant-btn-primary mr-2 mb-2"
              href={`/dashboard/rooms/${record._id}/messages`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Tin nhắn
            </a>
            <a
              className="ant-btn ant-btn-primary mr-2 mb-2"
              href={`${REACT_APP_BASE_URL}/room/${record._id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Phòng Chat
            </a>
          </>
        ),
    },
  ];

  const handleSearch = searchValue => setFilterOptions({ ...filterOptions, page: 1, searchValue });

  return (
    <Container>
      {(isOpen || editingId !== '') && (
        <ModalRoomForm
          onSubmit={onSubmit}
          visible={isOpen || editingId !== ''}
          title={editingId ? 'Cập nhật phòng chat' : 'Tạo mới phòng chat'}
          onCancel={handleCloseModal}
          editingId={editingId}
          initialValues={initialValues}
        />
      )}
      <ActionsBox
        title="Danh sách phòng chat"
        buttonLabel="Tạo mới"
        onSearch={handleSearch}
        onClick={() => setModalData({ isOpen: true, editingId: '' })}
      />
      <div className="table-box">
        <Table
          bordered
          rowKey={(row, index) => index}
          dataSource={rooms}
          columns={columns}
          loading={isFetching}
        />
        <PaginationBox
          pageSize={Number(filterOptions.pageSize)}
          total={total}
          current={filterOptions.page}
          onChange={page => {
            setFilterOptions({ ...filterOptions, page });
          }}
        />
      </div>
    </Container>
  );
};

export default connect(({ room }) => ({
  roomStore: room,
}))(withHeader(Rooms));
