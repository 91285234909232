// @ts-check
import pako from 'pako';
import { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import { isEmpty, concat } from 'lodash';
import { useThrottleFn } from 'ahooks';
import {
  MESSAGES_DELETE_SUCCESS,
  MESSAGES_NEW,
  ROOMS_ONLINE_RESPONSE,
  MESSAGES_ACTIVE,
} from 'components/storefront/Room/ducks';

import { createAction } from 'dorothy/utils';
import parser from 'socket.io-msgpack-parser';

const MESSAGE = 'message';
const SEND = 'send';
const SUBSCRIBE = 'subscribe';
const ROOM_ONLINE = 'room_online';
const MESSAGE_DELETE = 'message_delete';

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const reconstructMessage = valuesString => {
  const valuesArray = valuesString.split('|^');
  const getNextValue = () => valuesArray.shift();
  return {
    roomId: getNextValue(),
    message: getNextValue(),
    id: getNextValue(),
    message_type: getNextValue(),
    created_at: Number(getNextValue()),
    user: {
      avatar: getNextValue(),
      role: getNextValue(),
      id: getNextValue(),
      username: getNextValue(),
      fullname: getNextValue(),
      level: Number(getNextValue()),
      ip: getNextValue(),
    },
  };
};
export const useSocket = ({ room, user }, dispatch) => {
  const [connected, setConnected] = useState(false);
  const socketRef = useRef(null);
  // const availableSocketRef = useRef({});
  const socket = socketRef.current;
  const messageListRef = useRef([]);

  const { run } = useThrottleFn(
    data => {
      dispatch(createAction(MESSAGES_NEW, { data }));
      messageListRef.current = [];
    },
    { wait: 1000 },
  );

  // const { run: runRoomsOnline } = useDebounceFn(
  //   data => {
  //     dispatch(createAction(ROOMS_ONLINE_RESPONSE, { count: sum(Object.values(data) || []) }));
  //   },
  //   { wait: 500 },
  // );

  const send = message => {
    try {
      socket.emit(SEND, {
        ...message,
        room: room.id,
      });
    } catch (error) {}
  };

  useEffect(() => {
    if (!isEmpty(room)) {
      socketRef.current = io(SOCKET_URL, {
        parser,
        transports: ['websocket'],
        // withCredentials: true,
      });
      setConnected(true);
    }
  }, [room?.id]);

  // useEffect(() => {
  //   if (isEmpty(user)) {
  //     if (socket !== null) {
  //      // console.log('user - disconnect');
  //       socket.disconnect();
  //     }
  //     setConnected(false);
  //   }

  //   if (socket !== null) {
  //    // console.log('user - connect');
  //     socket.connect();
  //   } else {
  //     socketRef.current = io(SOCKET_URL, {
  //       transports: ['websocket', 'polling'],
  //       withCredentials: true,
  //     });
  //   }
  //   setConnected(true);
  // }, [user?.id, socket]);

  useEffect(() => {
    if (connected && !isEmpty(room)) {
      socket.off(MESSAGE);
      socket.on(MESSAGE, message => {
        const decompressed = pako.ungzip(message, { to: 'string' });
        message = reconstructMessage(decompressed);
        if (!isEmpty(user) && user?.id === message?.user?.id) {
          dispatch(createAction(MESSAGES_ACTIVE, message));
          return;
        }
        messageListRef.current = concat(messageListRef.current, [message]);
        run(messageListRef.current);
      });

      socket.off(ROOM_ONLINE);
      socket.on(ROOM_ONLINE, message => {
        // console.log(ROOM_ONLINE, message);
        dispatch(createAction(ROOMS_ONLINE_RESPONSE, message));
        // if (message.pid) {
        // availableSocketRef.current[message.pid] = message.count;
        // runRoomsOnline(availableSocketRef.current);
        // }
      });

      socket.off(MESSAGE_DELETE);
      socket.on(MESSAGE_DELETE, ({ id }) => {
        if (id) {
          dispatch(createAction(MESSAGES_DELETE_SUCCESS, id));
        }
      });

      socket.on('connect', () => {
        socket.emit(SUBSCRIBE, { room: room.id });
      });
    } else {
      if (socket) {
        socket.off(MESSAGE);
        socket.off(ROOM_ONLINE);
      }
    }
  }, [dispatch, connected, socket, room?.id, user]);

  return { socket, connected, send };
};
