import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Field } from 'formik';
import { pxToRem } from 'utils/common';

const Box = styled.div`
  position: relative;

  .label {
    margin-bottom: ${pxToRem(5)};
  }

  .field-content {
    .error-message {
      text-align: right;
      font-size: 12px;
      color: red;
      height: 18px;
      margin-top: 3px;
    }
  }

  &.inline {
    display: flex;
    align-items: start;

    .label {
      width: 150px;
      font-weight: 600;
      font-size: ${pxToRem(12)};
      margin-bottom: 0;
      letter-spacing: 0.29px;
      color: #272727;
    }

    .field-content {
      flex: 1;
      min-width: 0;

      .error-message {
        height: auto;
      }
    }
  }
`;

export default ({ component: Component, form, name, label, inline, labelClasses, ...props }) => {
  return (
    <Box className={classNames('field', { inline })}>
      {label && <p className={`label ${labelClasses}`}>{label}</p>}
      <div className="field-content">
        <Field {...props} name={name} component={Component} />
        {form && form.errors[name] ? (
          <p className="error-message">{form.errors[name]}</p>
        ) : (
          <div style={{ marginBottom: pxToRem(5) }}></div>
        )}
      </div>
    </Box>
  );
};
