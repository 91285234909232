import React from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import Button from 'antd-components/button';
import Field from 'antd-components/field';
import Input from 'antd-components/input';
import Modal from 'antd-components/modal';
import { UPDATE_PROFILE_REQUEST } from 'components/dashboard/Users/ducks';
import { useDispatch } from 'react-redux';
import { validation } from 'dorothy/utils/validation';
import Notification from 'antd-components/notification';

const StyledForm = styled.div`
  .action-box {
    display: flex;
    justify-content: flex-end;

    > button:nth-child(2) {
      margin-left: 10px;
    }
  }

  .field-group {
    margin-bottom: 15px;
  }
`;

const ModalUpdatePassword = props => {
  const { visible, title, onCancel } = props;
  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    password: validation.password,
    confirmPass: validation.passwordConfirmation,
  });

  const handleUpdate = data => {
    dispatch({
      type: UPDATE_PROFILE_REQUEST,
      payload: {
        password: data.password,
        callBack: success => {
          if (success) {
            onCancel();
            Notification.success('Đổi mật khẩu thành công');
          }
        },
      },
    });
  };

  const renderForm = ({ handleSubmit, ...form }) => (
    <Form className="form">
      <>
        <div className="field-group">
          <Field
            form={form}
            name="password"
            label="Mật khẩu Mới"
            type="password"
            component={Input}
          />
          <Field
            form={form}
            name="confirmPass"
            label="Nhập lại mật khẩu mới"
            type="password"
            component={Input}
          />
        </div>
        <div className="action-box">
          <Button size="small" htmlType="submit" type="primary" onClick={handleSubmit}>
            Lưu
          </Button>
          <Button size="small" onClick={onCancel}>
            Huỷ bỏ
          </Button>
        </div>
      </>
    </Form>
  );

  return (
    <Modal title={title} visible={visible} onCancel={onCancel}>
      <StyledForm>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{}}
          validationSchema={validationSchema}
          onSubmit={handleUpdate}
          component={renderForm}
        />
      </StyledForm>
    </Modal>
  );
};

export default ModalUpdatePassword;
