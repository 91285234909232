import { useState } from 'react';
import { get } from 'lodash';

import { callApi } from 'dorothy/utils';

export const useApi = url => {
  const [fetchData, setFetchData] = useState({
    entities: [],
    fetching: false,
    fetched: false,
    fetchError: false,
    fetchErrors: [],
  });
  const [getData, setGetData] = useState({
    entity: {},
    loading: false,
    loaded: false,
    loadError: false,
    loadErrors: [],
  });
  const [createData, setCreateData] = useState({
    creating: false,
    created: false,
    createError: false,
    createErrors: [],
  });
  const [updateData, setUpdateData] = useState({
    updating: false,
    updated: false,
    updateError: false,
    updateErrors: [],
  });
  const [deleteData, setDeleteData] = useState({
    deleting: false,
    deleted: false,
    deleteError: false,
    deleteErrors: [],
  });

  const fetchApi = data => {
    return new Promise((resolve, reject) => {
      try {
        setFetchData({
          ...fetchData,
          entities: [],
          fetching: true,
          fetched: false,
          fetchError: false,
          fetchErrors: [],
        });
        callApi('GET', url, data)
          .then(res => {
            setFetchData({ ...fetchData, entities: res?.data, fetching: false, fetched: true });
            resolve(res);
          })
          .catch(error => {
            setFetchData({
              ...fetchData,
              fetching: false,
              fetchError: true,
              fetchErrors: get(error, 'response.data.error.errors', []),
            });
          });
      } catch (error) {
        reject(error);
        setFetchData({
          ...fetchData,
          fetching: false,
          fetchError: true,
          fetchErrors: get(error, 'response.data.error.errors', []),
        });
      }
    });
  };

  const getApi = id => {
    return new Promise((resolve, reject) => {
      try {
        setGetData({ ...getData, loading: true, loaded: false, loadError: false, loadErrors: [] });
        callApi('GET', `${url}/${id}`)
          .then(res => {
            setGetData({ ...getData, entity: res?.data, loading: false, loaded: true });
            resolve(res);
          })
          .catch(error => {
            setGetData({
              ...getData,
              loading: false,
              loadError: true,
              loadErrors: get(error, 'response.data.error.errors', []),
            });
          });
      } catch (error) {
        reject(error);
        setGetData({
          ...getData,
          loading: false,
          loadError: true,
          loadErrors: get(error, 'response.data.error.errors', []),
        });
      }
    });
  };

  const createApi = data => {
    return new Promise((resolve, reject) => {
      try {
        setCreateData({
          ...createData,
          creating: true,
          created: false,
          createError: false,
          createErrors: [],
        });
        callApi('POST', url, data)
          .then(res => {
            setCreateData({ ...createData, creating: false, created: true });
            resolve(res);
          })
          .catch(error => {
            setCreateData({
              ...createData,
              creating: false,
              createError: true,
              createErrors: get(error, 'response.data.error.errors', []),
            });
          });
      } catch (error) {
        reject(error);
        setCreateData({
          ...createData,
          creating: false,
          createError: true,
          createErrors: get(error, 'response.data.error.errors', []),
        });
      }
    });
  };

  const updateApi = (id, data) => {
    return new Promise((resolve, reject) => {
      try {
        setUpdateData({
          ...updateData,
          updating: true,
          updated: false,
          updateError: false,
          updateErrors: [],
        });
        callApi('PUT', `${url}/${id}`, data)
          .then(res => {
            setUpdateData({ ...updateData, updating: false, updated: true });
            resolve(res);
          })
          .catch(error => {
            setUpdateData({
              ...updateData,
              updating: false,
              updateError: true,
              updateErrors: get(error, 'response.data.error.errors', []),
            });
          });
      } catch (error) {
        reject(error);
        setUpdateData({
          ...updateData,
          updating: false,
          updateError: true,
          updateErrors: get(error, 'response.data.error.errors', []),
        });
      }
    });
  };

  const deleteApi = id => {
    return new Promise((resolve, reject) => {
      try {
        setDeleteData({
          ...deleteData,
          deleting: true,
          deleted: false,
          deleteError: false,
          deleteErrors: [],
        });
        callApi('DELETE', `${url}/${id}`)
          .then(res => {
            setDeleteData({ ...deleteData, deleting: false, deleted: true });
            resolve(res);
          })
          .catch(error => {
            setDeleteData({
              ...deleteData,
              deleting: false,
              deleteError: true,
              deleteErrors: get(error, 'response.data.error.errors', []),
            });
          });
      } catch (error) {
        reject(error);
        setDeleteData({
          ...deleteData,
          deleting: false,
          deleteError: true,
          deleteErrors: get(error, 'response.data.error.errors', []),
        });
      }
    });
  };

  return {
    fetchApi,
    getApi,
    createApi,
    updateApi,
    deleteApi,
    fetchData,
    getData,
    createData,
    updateData,
    deleteData,
  };
};
