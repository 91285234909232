import React, { useState } from 'react';
import styled from 'styled-components';
import { Radio } from 'antd';

import Button from 'antd-components/button';
import Modal from 'antd-components/modal';
import { BAN_USER_REQUEST } from 'components/dashboard/Users/ducks';
import { useDispatch, connect } from 'react-redux';
import Notification from 'antd-components/notification';
import { useMessage } from 'hooks';

const StyledForm = styled.div`
  .action-box {
    display: flex;
    justify-content: flex-end;

    > button:nth-child(2) {
      margin-left: 10px;
    }
  }

  .field-group {
    margin-bottom: 20px;
  }
`;

const ONE_MINUTE_IN_MILISECOND = 60 * 1000;
const ONE_HOUR_IN_MILISECOND = 1000 * 60 * 60;
const ONE_DAY_IN_MILISECOND = 1000 * 60 * 60 * 24;

const ModalBan = props => {
  const { visible, title, onCancel, user, banUsername, className } = props;
  const { username, fullname, level, ip } = useMessage(user);
  const dispatch = useDispatch();

  const [deactiveTime, setDeactiveTime] = useState(5 * ONE_MINUTE_IN_MILISECOND);

  const handleBan = () => {
    const deactive = Date.now() + deactiveTime;

    dispatch({
      type: BAN_USER_REQUEST,
      payload: {
        username: banUsername || username,
        ip,
        deactive,
        callBack: success => {
          if (success) {
            onCancel();
            Notification.success('Cấm nhắn tin thành công');
          }
        },
      },
    });
  };

  const deactiveTimeList = [
    {
      label: '5 phút',
      value: 5 * ONE_MINUTE_IN_MILISECOND,
    },
    {
      label: '1 giờ',
      value: ONE_HOUR_IN_MILISECOND,
    },
    {
      label: '1 ngày',
      value: ONE_DAY_IN_MILISECOND,
    },
    {
      label: '7 ngày',
      value: 7 * ONE_DAY_IN_MILISECOND,
    },
  ];

  const handleChange = option => {
    // console.log('option: ', option);
    setDeactiveTime(option.target.value);
  };

  const renderForm = () => (
    <div>
      <div>Cấm nhắn tin:</div>
      <div className="field-group">
        <Radio.Group onChange={handleChange} value={deactiveTime}>
          {deactiveTimeList.map(option => (
            <Radio key={option.value} value={option.value}>
              {option.label}
            </Radio>
          ))}
        </Radio.Group>
      </div>
      <div className="action-box">
        <Button
          type="primary"
          size={className === 'admin-side' ? 'large' : 'small'}
          onClick={handleBan}
        >
          Cấm
        </Button>
        <Button size={className === 'admin-side' ? 'large' : 'small'} onClick={onCancel}>
          Huỷ bỏ
        </Button>
      </div>
    </div>
  );

  return (
    <Modal title={title} visible={visible} onCancel={onCancel} className={className}>
      <StyledForm>
        {user && (
          <div className="field-group">
            <div className="username">
              <span className="text-bold">Tên đăng nhập:</span> {username}
            </div>
            <div className="username">
              <span className="text-bold">Biệt danh:</span> {fullname}
            </div>
            <div className="username">
              <span className="text-bold">Level:</span> {level}
            </div>
            <div className="username">
              <span className="text-bold">Địa chỉ IP:</span> {ip}
            </div>
          </div>
        )}
        {renderForm()}
      </StyledForm>
    </Modal>
  );
};

export default React.memo(ModalBan);
