// import randomColor from 'randomcolor';
// import Color from 'color';
// var colorsRan = randomColor({
//   count: 60,
//   luminosity: 'dark',
//   hue: 'random',
// });
// const colorsRan600 = [
//   ...colorsRan,
//   ...colorsRan,
//   ...colorsRan,
//   ...colorsRan,
//   ...colorsRan,
//   ...colorsRan,
//   ...colorsRan,
//   ...colorsRan,
//   ...colorsRan,
//   ...colorsRan,
// ];
// const colorsRan600Fade = colors.map(item => {
//   const color = Color(item).fade(0.6).string();
//   return color;
// });
//// console.log('colors', JSON.stringify(colorsRan600));
//// console.log('colors', JSON.stringify(colorsRan600Fade));

export const colors = [
  '#b20844',
  '#619302',
  '#267a00',
  '#b57f01',
  '#a3104f',
  '#0a626d',
  '#07964c',
  '#48890b',
  '#8fa50e',
  '#600b84',
  '#0ea376',
  '#8c2a07',
  '#017265',
  '#129903',
  '#16890c',
  '#049b70',
  '#030375',
  '#090668',
  '#075e66',
  '#930c00',
  '#027a26',
  '#062e6b',
  '#5e990c',
  '#ba710b',
  '#d6b615',
  '#d3d313',
  '#dd10ef',
  '#bc0fa8',
  '#4b0489',
  '#040e60',
  '#bc0de8',
  '#99330a',
  '#460799',
  '#d88a15',
  '#c46607',
  '#07458c',
  '#e200b9',
  '#d36013',
  '#098787',
  '#074f7c',
  '#7da50e',
  '#250182',
  '#d15300',
  '#f2f916',
  '#99042e',
  '#044c68',
  '#11ad4a',
  '#041b6d',
  '#4a6d03',
  '#049907',
  '#088e8e',
  '#01990e',
  '#910e35',
  '#bd09e5',
  '#0ea569',
  '#e5e102',
  '#047c7c',
  '#ce066d',
  '#37048e',
  '#ed07d6',
  '#b20844',
  '#619302',
  '#267a00',
  '#b57f01',
  '#a3104f',
  '#0a626d',
  '#07964c',
  '#48890b',
  '#8fa50e',
  '#600b84',
  '#0ea376',
  '#8c2a07',
  '#017265',
  '#129903',
  '#16890c',
  '#049b70',
  '#030375',
  '#090668',
  '#075e66',
  '#930c00',
  '#027a26',
  '#062e6b',
  '#5e990c',
  '#ba710b',
  '#d6b615',
  '#d3d313',
  '#dd10ef',
  '#bc0fa8',
  '#4b0489',
  '#040e60',
  '#bc0de8',
  '#99330a',
  '#460799',
  '#d88a15',
  '#c46607',
  '#07458c',
  '#e200b9',
  '#d36013',
  '#098787',
  '#074f7c',
  '#7da50e',
  '#250182',
  '#d15300',
  '#f2f916',
  '#99042e',
  '#044c68',
  '#11ad4a',
  '#041b6d',
  '#4a6d03',
  '#049907',
  '#088e8e',
  '#01990e',
  '#910e35',
  '#bd09e5',
  '#0ea569',
  '#e5e102',
  '#047c7c',
  '#ce066d',
  '#37048e',
  '#ed07d6',
  '#b20844',
  '#619302',
  '#267a00',
  '#b57f01',
  '#a3104f',
  '#0a626d',
  '#07964c',
  '#48890b',
  '#8fa50e',
  '#600b84',
  '#0ea376',
  '#8c2a07',
  '#017265',
  '#129903',
  '#16890c',
  '#049b70',
  '#030375',
  '#090668',
  '#075e66',
  '#930c00',
  '#027a26',
  '#062e6b',
  '#5e990c',
  '#ba710b',
  '#d6b615',
  '#d3d313',
  '#dd10ef',
  '#bc0fa8',
  '#4b0489',
  '#040e60',
  '#bc0de8',
  '#99330a',
  '#460799',
  '#d88a15',
  '#c46607',
  '#07458c',
  '#e200b9',
  '#d36013',
  '#098787',
  '#074f7c',
  '#7da50e',
  '#250182',
  '#d15300',
  '#f2f916',
  '#99042e',
  '#044c68',
  '#11ad4a',
  '#041b6d',
  '#4a6d03',
  '#049907',
  '#088e8e',
  '#01990e',
  '#910e35',
  '#bd09e5',
  '#0ea569',
  '#e5e102',
  '#047c7c',
  '#ce066d',
  '#37048e',
  '#ed07d6',
  '#b20844',
  '#619302',
  '#267a00',
  '#b57f01',
  '#a3104f',
  '#0a626d',
  '#07964c',
  '#48890b',
  '#8fa50e',
  '#600b84',
  '#0ea376',
  '#8c2a07',
  '#017265',
  '#129903',
  '#16890c',
  '#049b70',
  '#030375',
  '#090668',
  '#075e66',
  '#930c00',
  '#027a26',
  '#062e6b',
  '#5e990c',
  '#ba710b',
  '#d6b615',
  '#d3d313',
  '#dd10ef',
  '#bc0fa8',
  '#4b0489',
  '#040e60',
  '#bc0de8',
  '#99330a',
  '#460799',
  '#d88a15',
  '#c46607',
  '#07458c',
  '#e200b9',
  '#d36013',
  '#098787',
  '#074f7c',
  '#7da50e',
  '#250182',
  '#d15300',
  '#f2f916',
  '#99042e',
  '#044c68',
  '#11ad4a',
  '#041b6d',
  '#4a6d03',
  '#049907',
  '#088e8e',
  '#01990e',
  '#910e35',
  '#bd09e5',
  '#0ea569',
  '#e5e102',
  '#047c7c',
  '#ce066d',
  '#37048e',
  '#ed07d6',
  '#b20844',
  '#619302',
  '#267a00',
  '#b57f01',
  '#a3104f',
  '#0a626d',
  '#07964c',
  '#48890b',
  '#8fa50e',
  '#600b84',
  '#0ea376',
  '#8c2a07',
  '#017265',
  '#129903',
  '#16890c',
  '#049b70',
  '#030375',
  '#090668',
  '#075e66',
  '#930c00',
  '#027a26',
  '#062e6b',
  '#5e990c',
  '#ba710b',
  '#d6b615',
  '#d3d313',
  '#dd10ef',
  '#bc0fa8',
  '#4b0489',
  '#040e60',
  '#bc0de8',
  '#99330a',
  '#460799',
  '#d88a15',
  '#c46607',
  '#07458c',
  '#e200b9',
  '#d36013',
  '#098787',
  '#074f7c',
  '#7da50e',
  '#250182',
  '#d15300',
  '#f2f916',
  '#99042e',
  '#044c68',
  '#11ad4a',
  '#041b6d',
  '#4a6d03',
  '#049907',
  '#088e8e',
  '#01990e',
  '#910e35',
  '#bd09e5',
  '#0ea569',
  '#e5e102',
  '#047c7c',
  '#ce066d',
  '#37048e',
  '#ed07d6',
  '#b20844',
  '#619302',
  '#267a00',
  '#b57f01',
  '#a3104f',
  '#0a626d',
  '#07964c',
  '#48890b',
  '#8fa50e',
  '#600b84',
  '#0ea376',
  '#8c2a07',
  '#017265',
  '#129903',
  '#16890c',
  '#049b70',
  '#030375',
  '#090668',
  '#075e66',
  '#930c00',
  '#027a26',
  '#062e6b',
  '#5e990c',
  '#ba710b',
  '#d6b615',
  '#d3d313',
  '#dd10ef',
  '#bc0fa8',
  '#4b0489',
  '#040e60',
  '#bc0de8',
  '#99330a',
  '#460799',
  '#d88a15',
  '#c46607',
  '#07458c',
  '#e200b9',
  '#d36013',
  '#098787',
  '#074f7c',
  '#7da50e',
  '#250182',
  '#d15300',
  '#f2f916',
  '#99042e',
  '#044c68',
  '#11ad4a',
  '#041b6d',
  '#4a6d03',
  '#049907',
  '#088e8e',
  '#01990e',
  '#910e35',
  '#bd09e5',
  '#0ea569',
  '#e5e102',
  '#047c7c',
  '#ce066d',
  '#37048e',
  '#ed07d6',
  '#b20844',
  '#619302',
  '#267a00',
  '#b57f01',
  '#a3104f',
  '#0a626d',
  '#07964c',
  '#48890b',
  '#8fa50e',
  '#600b84',
  '#0ea376',
  '#8c2a07',
  '#017265',
  '#129903',
  '#16890c',
  '#049b70',
  '#030375',
  '#090668',
  '#075e66',
  '#930c00',
  '#027a26',
  '#062e6b',
  '#5e990c',
  '#ba710b',
  '#d6b615',
  '#d3d313',
  '#dd10ef',
  '#bc0fa8',
  '#4b0489',
  '#040e60',
  '#bc0de8',
  '#99330a',
  '#460799',
  '#d88a15',
  '#c46607',
  '#07458c',
  '#e200b9',
  '#d36013',
  '#098787',
  '#074f7c',
  '#7da50e',
  '#250182',
  '#d15300',
  '#f2f916',
  '#99042e',
  '#044c68',
  '#11ad4a',
  '#041b6d',
  '#4a6d03',
  '#049907',
  '#088e8e',
  '#01990e',
  '#910e35',
  '#bd09e5',
  '#0ea569',
  '#e5e102',
  '#047c7c',
  '#ce066d',
  '#37048e',
  '#ed07d6',
  '#b20844',
  '#619302',
  '#267a00',
  '#b57f01',
  '#a3104f',
  '#0a626d',
  '#07964c',
  '#48890b',
  '#8fa50e',
  '#600b84',
  '#0ea376',
  '#8c2a07',
  '#017265',
  '#129903',
  '#16890c',
  '#049b70',
  '#030375',
  '#090668',
  '#075e66',
  '#930c00',
  '#027a26',
  '#062e6b',
  '#5e990c',
  '#ba710b',
  '#d6b615',
  '#d3d313',
  '#dd10ef',
  '#bc0fa8',
  '#4b0489',
  '#040e60',
  '#bc0de8',
  '#99330a',
  '#460799',
  '#d88a15',
  '#c46607',
  '#07458c',
  '#e200b9',
  '#d36013',
  '#098787',
  '#074f7c',
  '#7da50e',
  '#250182',
  '#d15300',
  '#f2f916',
  '#99042e',
  '#044c68',
  '#11ad4a',
  '#041b6d',
  '#4a6d03',
  '#049907',
  '#088e8e',
  '#01990e',
  '#910e35',
  '#bd09e5',
  '#0ea569',
  '#e5e102',
  '#047c7c',
  '#ce066d',
  '#37048e',
  '#ed07d6',
  '#b20844',
  '#619302',
  '#267a00',
  '#b57f01',
  '#a3104f',
  '#0a626d',
  '#07964c',
  '#48890b',
  '#8fa50e',
  '#600b84',
  '#0ea376',
  '#8c2a07',
  '#017265',
  '#129903',
  '#16890c',
  '#049b70',
  '#030375',
  '#090668',
  '#075e66',
  '#930c00',
  '#027a26',
  '#062e6b',
  '#5e990c',
  '#ba710b',
  '#d6b615',
  '#d3d313',
  '#dd10ef',
  '#bc0fa8',
  '#4b0489',
  '#040e60',
  '#bc0de8',
  '#99330a',
  '#460799',
  '#d88a15',
  '#c46607',
  '#07458c',
  '#e200b9',
  '#d36013',
  '#098787',
  '#074f7c',
  '#7da50e',
  '#250182',
  '#d15300',
  '#f2f916',
  '#99042e',
  '#044c68',
  '#11ad4a',
  '#041b6d',
  '#4a6d03',
  '#049907',
  '#088e8e',
  '#01990e',
  '#910e35',
  '#bd09e5',
  '#0ea569',
  '#e5e102',
  '#047c7c',
  '#ce066d',
  '#37048e',
  '#ed07d6',
  '#b20844',
  '#619302',
  '#267a00',
  '#b57f01',
  '#a3104f',
  '#0a626d',
  '#07964c',
  '#48890b',
  '#8fa50e',
  '#600b84',
  '#0ea376',
  '#8c2a07',
  '#017265',
  '#129903',
  '#16890c',
  '#049b70',
  '#030375',
  '#090668',
  '#075e66',
  '#930c00',
  '#027a26',
  '#062e6b',
  '#5e990c',
  '#ba710b',
  '#d6b615',
  '#d3d313',
  '#dd10ef',
  '#bc0fa8',
  '#4b0489',
  '#040e60',
  '#bc0de8',
  '#99330a',
  '#460799',
  '#d88a15',
  '#c46607',
  '#07458c',
  '#e200b9',
  '#d36013',
  '#098787',
  '#074f7c',
  '#7da50e',
  '#250182',
  '#d15300',
  '#f2f916',
  '#99042e',
  '#044c68',
  '#11ad4a',
  '#041b6d',
  '#4a6d03',
  '#049907',
  '#088e8e',
  '#01990e',
  '#910e35',
  '#bd09e5',
  '#0ea569',
  '#e5e102',
  '#047c7c',
  '#ce066d',
  '#37048e',
  '#ed07d6',
];
export const colorsText = [
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
  '#fff',
];
export const colorsFade = [
  'rgba(178, 8, 68, 0.4)',
  'rgba(97, 147, 2, 0.4)',
  'rgba(38, 122, 0, 0.4)',
  'rgba(181, 127, 1, 0.4)',
  'rgba(163, 16, 79, 0.4)',
  'rgba(10, 98, 109, 0.4)',
  'rgba(7, 150, 76, 0.4)',
  'rgba(72, 137, 11, 0.4)',
  'rgba(143, 165, 14, 0.4)',
  'rgba(96, 11, 132, 0.4)',
  'rgba(14, 163, 118, 0.4)',
  'rgba(140, 42, 7, 0.4)',
  'rgba(1, 114, 101, 0.4)',
  'rgba(18, 153, 3, 0.4)',
  'rgba(22, 137, 12, 0.4)',
  'rgba(4, 155, 112, 0.4)',
  'rgba(3, 3, 117, 0.4)',
  'rgba(9, 6, 104, 0.4)',
  'rgba(7, 94, 102, 0.4)',
  'rgba(147, 12, 0, 0.4)',
  'rgba(2, 122, 38, 0.4)',
  'rgba(6, 46, 107, 0.4)',
  'rgba(94, 153, 12, 0.4)',
  'rgba(186, 113, 11, 0.4)',
  'rgba(214, 182, 21, 0.4)',
  'rgba(211, 211, 19, 0.4)',
  'rgba(221, 16, 239, 0.4)',
  'rgba(188, 15, 168, 0.4)',
  'rgba(75, 4, 137, 0.4)',
  'rgba(4, 14, 96, 0.4)',
  'rgba(188, 13, 232, 0.4)',
  'rgba(153, 51, 10, 0.4)',
  'rgba(70, 7, 153, 0.4)',
  'rgba(216, 138, 21, 0.4)',
  'rgba(196, 102, 7, 0.4)',
  'rgba(7, 69, 140, 0.4)',
  'rgba(226, 0, 185, 0.4)',
  'rgba(211, 96, 19, 0.4)',
  'rgba(9, 135, 135, 0.4)',
  'rgba(7, 79, 124, 0.4)',
  'rgba(125, 165, 14, 0.4)',
  'rgba(37, 1, 130, 0.4)',
  'rgba(209, 83, 0, 0.4)',
  'rgba(242, 249, 22, 0.4)',
  'rgba(153, 4, 46, 0.4)',
  'rgba(4, 76, 104, 0.4)',
  'rgba(17, 173, 74, 0.4)',
  'rgba(4, 27, 109, 0.4)',
  'rgba(74, 109, 3, 0.4)',
  'rgba(4, 153, 7, 0.4)',
  'rgba(8, 142, 142, 0.4)',
  'rgba(1, 153, 14, 0.4)',
  'rgba(145, 14, 53, 0.4)',
  'rgba(189, 9, 229, 0.4)',
  'rgba(14, 165, 105, 0.4)',
  'rgba(229, 225, 2, 0.4)',
  'rgba(4, 124, 124, 0.4)',
  'rgba(206, 6, 109, 0.4)',
  'rgba(55, 4, 142, 0.4)',
  'rgba(237, 7, 214, 0.4)',
  'rgba(178, 8, 68, 0.4)',
  'rgba(97, 147, 2, 0.4)',
  'rgba(38, 122, 0, 0.4)',
  'rgba(181, 127, 1, 0.4)',
  'rgba(163, 16, 79, 0.4)',
  'rgba(10, 98, 109, 0.4)',
  'rgba(7, 150, 76, 0.4)',
  'rgba(72, 137, 11, 0.4)',
  'rgba(143, 165, 14, 0.4)',
  'rgba(96, 11, 132, 0.4)',
  'rgba(14, 163, 118, 0.4)',
  'rgba(140, 42, 7, 0.4)',
  'rgba(1, 114, 101, 0.4)',
  'rgba(18, 153, 3, 0.4)',
  'rgba(22, 137, 12, 0.4)',
  'rgba(4, 155, 112, 0.4)',
  'rgba(3, 3, 117, 0.4)',
  'rgba(9, 6, 104, 0.4)',
  'rgba(7, 94, 102, 0.4)',
  'rgba(147, 12, 0, 0.4)',
  'rgba(2, 122, 38, 0.4)',
  'rgba(6, 46, 107, 0.4)',
  'rgba(94, 153, 12, 0.4)',
  'rgba(186, 113, 11, 0.4)',
  'rgba(214, 182, 21, 0.4)',
  'rgba(211, 211, 19, 0.4)',
  'rgba(221, 16, 239, 0.4)',
  'rgba(188, 15, 168, 0.4)',
  'rgba(75, 4, 137, 0.4)',
  'rgba(4, 14, 96, 0.4)',
  'rgba(188, 13, 232, 0.4)',
  'rgba(153, 51, 10, 0.4)',
  'rgba(70, 7, 153, 0.4)',
  'rgba(216, 138, 21, 0.4)',
  'rgba(196, 102, 7, 0.4)',
  'rgba(7, 69, 140, 0.4)',
  'rgba(226, 0, 185, 0.4)',
  'rgba(211, 96, 19, 0.4)',
  'rgba(9, 135, 135, 0.4)',
  'rgba(7, 79, 124, 0.4)',
  'rgba(125, 165, 14, 0.4)',
  'rgba(37, 1, 130, 0.4)',
  'rgba(209, 83, 0, 0.4)',
  'rgba(242, 249, 22, 0.4)',
  'rgba(153, 4, 46, 0.4)',
  'rgba(4, 76, 104, 0.4)',
  'rgba(17, 173, 74, 0.4)',
  'rgba(4, 27, 109, 0.4)',
  'rgba(74, 109, 3, 0.4)',
  'rgba(4, 153, 7, 0.4)',
  'rgba(8, 142, 142, 0.4)',
  'rgba(1, 153, 14, 0.4)',
  'rgba(145, 14, 53, 0.4)',
  'rgba(189, 9, 229, 0.4)',
  'rgba(14, 165, 105, 0.4)',
  'rgba(229, 225, 2, 0.4)',
  'rgba(4, 124, 124, 0.4)',
  'rgba(206, 6, 109, 0.4)',
  'rgba(55, 4, 142, 0.4)',
  'rgba(237, 7, 214, 0.4)',
  'rgba(178, 8, 68, 0.4)',
  'rgba(97, 147, 2, 0.4)',
  'rgba(38, 122, 0, 0.4)',
  'rgba(181, 127, 1, 0.4)',
  'rgba(163, 16, 79, 0.4)',
  'rgba(10, 98, 109, 0.4)',
  'rgba(7, 150, 76, 0.4)',
  'rgba(72, 137, 11, 0.4)',
  'rgba(143, 165, 14, 0.4)',
  'rgba(96, 11, 132, 0.4)',
  'rgba(14, 163, 118, 0.4)',
  'rgba(140, 42, 7, 0.4)',
  'rgba(1, 114, 101, 0.4)',
  'rgba(18, 153, 3, 0.4)',
  'rgba(22, 137, 12, 0.4)',
  'rgba(4, 155, 112, 0.4)',
  'rgba(3, 3, 117, 0.4)',
  'rgba(9, 6, 104, 0.4)',
  'rgba(7, 94, 102, 0.4)',
  'rgba(147, 12, 0, 0.4)',
  'rgba(2, 122, 38, 0.4)',
  'rgba(6, 46, 107, 0.4)',
  'rgba(94, 153, 12, 0.4)',
  'rgba(186, 113, 11, 0.4)',
  'rgba(214, 182, 21, 0.4)',
  'rgba(211, 211, 19, 0.4)',
  'rgba(221, 16, 239, 0.4)',
  'rgba(188, 15, 168, 0.4)',
  'rgba(75, 4, 137, 0.4)',
  'rgba(4, 14, 96, 0.4)',
  'rgba(188, 13, 232, 0.4)',
  'rgba(153, 51, 10, 0.4)',
  'rgba(70, 7, 153, 0.4)',
  'rgba(216, 138, 21, 0.4)',
  'rgba(196, 102, 7, 0.4)',
  'rgba(7, 69, 140, 0.4)',
  'rgba(226, 0, 185, 0.4)',
  'rgba(211, 96, 19, 0.4)',
  'rgba(9, 135, 135, 0.4)',
  'rgba(7, 79, 124, 0.4)',
  'rgba(125, 165, 14, 0.4)',
  'rgba(37, 1, 130, 0.4)',
  'rgba(209, 83, 0, 0.4)',
  'rgba(242, 249, 22, 0.4)',
  'rgba(153, 4, 46, 0.4)',
  'rgba(4, 76, 104, 0.4)',
  'rgba(17, 173, 74, 0.4)',
  'rgba(4, 27, 109, 0.4)',
  'rgba(74, 109, 3, 0.4)',
  'rgba(4, 153, 7, 0.4)',
  'rgba(8, 142, 142, 0.4)',
  'rgba(1, 153, 14, 0.4)',
  'rgba(145, 14, 53, 0.4)',
  'rgba(189, 9, 229, 0.4)',
  'rgba(14, 165, 105, 0.4)',
  'rgba(229, 225, 2, 0.4)',
  'rgba(4, 124, 124, 0.4)',
  'rgba(206, 6, 109, 0.4)',
  'rgba(55, 4, 142, 0.4)',
  'rgba(237, 7, 214, 0.4)',
  'rgba(178, 8, 68, 0.4)',
  'rgba(97, 147, 2, 0.4)',
  'rgba(38, 122, 0, 0.4)',
  'rgba(181, 127, 1, 0.4)',
  'rgba(163, 16, 79, 0.4)',
  'rgba(10, 98, 109, 0.4)',
  'rgba(7, 150, 76, 0.4)',
  'rgba(72, 137, 11, 0.4)',
  'rgba(143, 165, 14, 0.4)',
  'rgba(96, 11, 132, 0.4)',
  'rgba(14, 163, 118, 0.4)',
  'rgba(140, 42, 7, 0.4)',
  'rgba(1, 114, 101, 0.4)',
  'rgba(18, 153, 3, 0.4)',
  'rgba(22, 137, 12, 0.4)',
  'rgba(4, 155, 112, 0.4)',
  'rgba(3, 3, 117, 0.4)',
  'rgba(9, 6, 104, 0.4)',
  'rgba(7, 94, 102, 0.4)',
  'rgba(147, 12, 0, 0.4)',
  'rgba(2, 122, 38, 0.4)',
  'rgba(6, 46, 107, 0.4)',
  'rgba(94, 153, 12, 0.4)',
  'rgba(186, 113, 11, 0.4)',
  'rgba(214, 182, 21, 0.4)',
  'rgba(211, 211, 19, 0.4)',
  'rgba(221, 16, 239, 0.4)',
  'rgba(188, 15, 168, 0.4)',
  'rgba(75, 4, 137, 0.4)',
  'rgba(4, 14, 96, 0.4)',
  'rgba(188, 13, 232, 0.4)',
  'rgba(153, 51, 10, 0.4)',
  'rgba(70, 7, 153, 0.4)',
  'rgba(216, 138, 21, 0.4)',
  'rgba(196, 102, 7, 0.4)',
  'rgba(7, 69, 140, 0.4)',
  'rgba(226, 0, 185, 0.4)',
  'rgba(211, 96, 19, 0.4)',
  'rgba(9, 135, 135, 0.4)',
  'rgba(7, 79, 124, 0.4)',
  'rgba(125, 165, 14, 0.4)',
  'rgba(37, 1, 130, 0.4)',
  'rgba(209, 83, 0, 0.4)',
  'rgba(242, 249, 22, 0.4)',
  'rgba(153, 4, 46, 0.4)',
  'rgba(4, 76, 104, 0.4)',
  'rgba(17, 173, 74, 0.4)',
  'rgba(4, 27, 109, 0.4)',
  'rgba(74, 109, 3, 0.4)',
  'rgba(4, 153, 7, 0.4)',
  'rgba(8, 142, 142, 0.4)',
  'rgba(1, 153, 14, 0.4)',
  'rgba(145, 14, 53, 0.4)',
  'rgba(189, 9, 229, 0.4)',
  'rgba(14, 165, 105, 0.4)',
  'rgba(229, 225, 2, 0.4)',
  'rgba(4, 124, 124, 0.4)',
  'rgba(206, 6, 109, 0.4)',
  'rgba(55, 4, 142, 0.4)',
  'rgba(237, 7, 214, 0.4)',
  'rgba(178, 8, 68, 0.4)',
  'rgba(97, 147, 2, 0.4)',
  'rgba(38, 122, 0, 0.4)',
  'rgba(181, 127, 1, 0.4)',
  'rgba(163, 16, 79, 0.4)',
  'rgba(10, 98, 109, 0.4)',
  'rgba(7, 150, 76, 0.4)',
  'rgba(72, 137, 11, 0.4)',
  'rgba(143, 165, 14, 0.4)',
  'rgba(96, 11, 132, 0.4)',
  'rgba(14, 163, 118, 0.4)',
  'rgba(140, 42, 7, 0.4)',
  'rgba(1, 114, 101, 0.4)',
  'rgba(18, 153, 3, 0.4)',
  'rgba(22, 137, 12, 0.4)',
  'rgba(4, 155, 112, 0.4)',
  'rgba(3, 3, 117, 0.4)',
  'rgba(9, 6, 104, 0.4)',
  'rgba(7, 94, 102, 0.4)',
  'rgba(147, 12, 0, 0.4)',
  'rgba(2, 122, 38, 0.4)',
  'rgba(6, 46, 107, 0.4)',
  'rgba(94, 153, 12, 0.4)',
  'rgba(186, 113, 11, 0.4)',
  'rgba(214, 182, 21, 0.4)',
  'rgba(211, 211, 19, 0.4)',
  'rgba(221, 16, 239, 0.4)',
  'rgba(188, 15, 168, 0.4)',
  'rgba(75, 4, 137, 0.4)',
  'rgba(4, 14, 96, 0.4)',
  'rgba(188, 13, 232, 0.4)',
  'rgba(153, 51, 10, 0.4)',
  'rgba(70, 7, 153, 0.4)',
  'rgba(216, 138, 21, 0.4)',
  'rgba(196, 102, 7, 0.4)',
  'rgba(7, 69, 140, 0.4)',
  'rgba(226, 0, 185, 0.4)',
  'rgba(211, 96, 19, 0.4)',
  'rgba(9, 135, 135, 0.4)',
  'rgba(7, 79, 124, 0.4)',
  'rgba(125, 165, 14, 0.4)',
  'rgba(37, 1, 130, 0.4)',
  'rgba(209, 83, 0, 0.4)',
  'rgba(242, 249, 22, 0.4)',
  'rgba(153, 4, 46, 0.4)',
  'rgba(4, 76, 104, 0.4)',
  'rgba(17, 173, 74, 0.4)',
  'rgba(4, 27, 109, 0.4)',
  'rgba(74, 109, 3, 0.4)',
  'rgba(4, 153, 7, 0.4)',
  'rgba(8, 142, 142, 0.4)',
  'rgba(1, 153, 14, 0.4)',
  'rgba(145, 14, 53, 0.4)',
  'rgba(189, 9, 229, 0.4)',
  'rgba(14, 165, 105, 0.4)',
  'rgba(229, 225, 2, 0.4)',
  'rgba(4, 124, 124, 0.4)',
  'rgba(206, 6, 109, 0.4)',
  'rgba(55, 4, 142, 0.4)',
  'rgba(237, 7, 214, 0.4)',
  'rgba(178, 8, 68, 0.4)',
  'rgba(97, 147, 2, 0.4)',
  'rgba(38, 122, 0, 0.4)',
  'rgba(181, 127, 1, 0.4)',
  'rgba(163, 16, 79, 0.4)',
  'rgba(10, 98, 109, 0.4)',
  'rgba(7, 150, 76, 0.4)',
  'rgba(72, 137, 11, 0.4)',
  'rgba(143, 165, 14, 0.4)',
  'rgba(96, 11, 132, 0.4)',
  'rgba(14, 163, 118, 0.4)',
  'rgba(140, 42, 7, 0.4)',
  'rgba(1, 114, 101, 0.4)',
  'rgba(18, 153, 3, 0.4)',
  'rgba(22, 137, 12, 0.4)',
  'rgba(4, 155, 112, 0.4)',
  'rgba(3, 3, 117, 0.4)',
  'rgba(9, 6, 104, 0.4)',
  'rgba(7, 94, 102, 0.4)',
  'rgba(147, 12, 0, 0.4)',
  'rgba(2, 122, 38, 0.4)',
  'rgba(6, 46, 107, 0.4)',
  'rgba(94, 153, 12, 0.4)',
  'rgba(186, 113, 11, 0.4)',
  'rgba(214, 182, 21, 0.4)',
  'rgba(211, 211, 19, 0.4)',
  'rgba(221, 16, 239, 0.4)',
  'rgba(188, 15, 168, 0.4)',
  'rgba(75, 4, 137, 0.4)',
  'rgba(4, 14, 96, 0.4)',
  'rgba(188, 13, 232, 0.4)',
  'rgba(153, 51, 10, 0.4)',
  'rgba(70, 7, 153, 0.4)',
  'rgba(216, 138, 21, 0.4)',
  'rgba(196, 102, 7, 0.4)',
  'rgba(7, 69, 140, 0.4)',
  'rgba(226, 0, 185, 0.4)',
  'rgba(211, 96, 19, 0.4)',
  'rgba(9, 135, 135, 0.4)',
  'rgba(7, 79, 124, 0.4)',
  'rgba(125, 165, 14, 0.4)',
  'rgba(37, 1, 130, 0.4)',
  'rgba(209, 83, 0, 0.4)',
  'rgba(242, 249, 22, 0.4)',
  'rgba(153, 4, 46, 0.4)',
  'rgba(4, 76, 104, 0.4)',
  'rgba(17, 173, 74, 0.4)',
  'rgba(4, 27, 109, 0.4)',
  'rgba(74, 109, 3, 0.4)',
  'rgba(4, 153, 7, 0.4)',
  'rgba(8, 142, 142, 0.4)',
  'rgba(1, 153, 14, 0.4)',
  'rgba(145, 14, 53, 0.4)',
  'rgba(189, 9, 229, 0.4)',
  'rgba(14, 165, 105, 0.4)',
  'rgba(229, 225, 2, 0.4)',
  'rgba(4, 124, 124, 0.4)',
  'rgba(206, 6, 109, 0.4)',
  'rgba(55, 4, 142, 0.4)',
  'rgba(237, 7, 214, 0.4)',
  'rgba(178, 8, 68, 0.4)',
  'rgba(97, 147, 2, 0.4)',
  'rgba(38, 122, 0, 0.4)',
  'rgba(181, 127, 1, 0.4)',
  'rgba(163, 16, 79, 0.4)',
  'rgba(10, 98, 109, 0.4)',
  'rgba(7, 150, 76, 0.4)',
  'rgba(72, 137, 11, 0.4)',
  'rgba(143, 165, 14, 0.4)',
  'rgba(96, 11, 132, 0.4)',
  'rgba(14, 163, 118, 0.4)',
  'rgba(140, 42, 7, 0.4)',
  'rgba(1, 114, 101, 0.4)',
  'rgba(18, 153, 3, 0.4)',
  'rgba(22, 137, 12, 0.4)',
  'rgba(4, 155, 112, 0.4)',
  'rgba(3, 3, 117, 0.4)',
  'rgba(9, 6, 104, 0.4)',
  'rgba(7, 94, 102, 0.4)',
  'rgba(147, 12, 0, 0.4)',
  'rgba(2, 122, 38, 0.4)',
  'rgba(6, 46, 107, 0.4)',
  'rgba(94, 153, 12, 0.4)',
  'rgba(186, 113, 11, 0.4)',
  'rgba(214, 182, 21, 0.4)',
  'rgba(211, 211, 19, 0.4)',
  'rgba(221, 16, 239, 0.4)',
  'rgba(188, 15, 168, 0.4)',
  'rgba(75, 4, 137, 0.4)',
  'rgba(4, 14, 96, 0.4)',
  'rgba(188, 13, 232, 0.4)',
  'rgba(153, 51, 10, 0.4)',
  'rgba(70, 7, 153, 0.4)',
  'rgba(216, 138, 21, 0.4)',
  'rgba(196, 102, 7, 0.4)',
  'rgba(7, 69, 140, 0.4)',
  'rgba(226, 0, 185, 0.4)',
  'rgba(211, 96, 19, 0.4)',
  'rgba(9, 135, 135, 0.4)',
  'rgba(7, 79, 124, 0.4)',
  'rgba(125, 165, 14, 0.4)',
  'rgba(37, 1, 130, 0.4)',
  'rgba(209, 83, 0, 0.4)',
  'rgba(242, 249, 22, 0.4)',
  'rgba(153, 4, 46, 0.4)',
  'rgba(4, 76, 104, 0.4)',
  'rgba(17, 173, 74, 0.4)',
  'rgba(4, 27, 109, 0.4)',
  'rgba(74, 109, 3, 0.4)',
  'rgba(4, 153, 7, 0.4)',
  'rgba(8, 142, 142, 0.4)',
  'rgba(1, 153, 14, 0.4)',
  'rgba(145, 14, 53, 0.4)',
  'rgba(189, 9, 229, 0.4)',
  'rgba(14, 165, 105, 0.4)',
  'rgba(229, 225, 2, 0.4)',
  'rgba(4, 124, 124, 0.4)',
  'rgba(206, 6, 109, 0.4)',
  'rgba(55, 4, 142, 0.4)',
  'rgba(237, 7, 214, 0.4)',
  'rgba(178, 8, 68, 0.4)',
  'rgba(97, 147, 2, 0.4)',
  'rgba(38, 122, 0, 0.4)',
  'rgba(181, 127, 1, 0.4)',
  'rgba(163, 16, 79, 0.4)',
  'rgba(10, 98, 109, 0.4)',
  'rgba(7, 150, 76, 0.4)',
  'rgba(72, 137, 11, 0.4)',
  'rgba(143, 165, 14, 0.4)',
  'rgba(96, 11, 132, 0.4)',
  'rgba(14, 163, 118, 0.4)',
  'rgba(140, 42, 7, 0.4)',
  'rgba(1, 114, 101, 0.4)',
  'rgba(18, 153, 3, 0.4)',
  'rgba(22, 137, 12, 0.4)',
  'rgba(4, 155, 112, 0.4)',
  'rgba(3, 3, 117, 0.4)',
  'rgba(9, 6, 104, 0.4)',
  'rgba(7, 94, 102, 0.4)',
  'rgba(147, 12, 0, 0.4)',
  'rgba(2, 122, 38, 0.4)',
  'rgba(6, 46, 107, 0.4)',
  'rgba(94, 153, 12, 0.4)',
  'rgba(186, 113, 11, 0.4)',
  'rgba(214, 182, 21, 0.4)',
  'rgba(211, 211, 19, 0.4)',
  'rgba(221, 16, 239, 0.4)',
  'rgba(188, 15, 168, 0.4)',
  'rgba(75, 4, 137, 0.4)',
  'rgba(4, 14, 96, 0.4)',
  'rgba(188, 13, 232, 0.4)',
  'rgba(153, 51, 10, 0.4)',
  'rgba(70, 7, 153, 0.4)',
  'rgba(216, 138, 21, 0.4)',
  'rgba(196, 102, 7, 0.4)',
  'rgba(7, 69, 140, 0.4)',
  'rgba(226, 0, 185, 0.4)',
  'rgba(211, 96, 19, 0.4)',
  'rgba(9, 135, 135, 0.4)',
  'rgba(7, 79, 124, 0.4)',
  'rgba(125, 165, 14, 0.4)',
  'rgba(37, 1, 130, 0.4)',
  'rgba(209, 83, 0, 0.4)',
  'rgba(242, 249, 22, 0.4)',
  'rgba(153, 4, 46, 0.4)',
  'rgba(4, 76, 104, 0.4)',
  'rgba(17, 173, 74, 0.4)',
  'rgba(4, 27, 109, 0.4)',
  'rgba(74, 109, 3, 0.4)',
  'rgba(4, 153, 7, 0.4)',
  'rgba(8, 142, 142, 0.4)',
  'rgba(1, 153, 14, 0.4)',
  'rgba(145, 14, 53, 0.4)',
  'rgba(189, 9, 229, 0.4)',
  'rgba(14, 165, 105, 0.4)',
  'rgba(229, 225, 2, 0.4)',
  'rgba(4, 124, 124, 0.4)',
  'rgba(206, 6, 109, 0.4)',
  'rgba(55, 4, 142, 0.4)',
  'rgba(237, 7, 214, 0.4)',
  'rgba(178, 8, 68, 0.4)',
  'rgba(97, 147, 2, 0.4)',
  'rgba(38, 122, 0, 0.4)',
  'rgba(181, 127, 1, 0.4)',
  'rgba(163, 16, 79, 0.4)',
  'rgba(10, 98, 109, 0.4)',
  'rgba(7, 150, 76, 0.4)',
  'rgba(72, 137, 11, 0.4)',
  'rgba(143, 165, 14, 0.4)',
  'rgba(96, 11, 132, 0.4)',
  'rgba(14, 163, 118, 0.4)',
  'rgba(140, 42, 7, 0.4)',
  'rgba(1, 114, 101, 0.4)',
  'rgba(18, 153, 3, 0.4)',
  'rgba(22, 137, 12, 0.4)',
  'rgba(4, 155, 112, 0.4)',
  'rgba(3, 3, 117, 0.4)',
  'rgba(9, 6, 104, 0.4)',
  'rgba(7, 94, 102, 0.4)',
  'rgba(147, 12, 0, 0.4)',
  'rgba(2, 122, 38, 0.4)',
  'rgba(6, 46, 107, 0.4)',
  'rgba(94, 153, 12, 0.4)',
  'rgba(186, 113, 11, 0.4)',
  'rgba(214, 182, 21, 0.4)',
  'rgba(211, 211, 19, 0.4)',
  'rgba(221, 16, 239, 0.4)',
  'rgba(188, 15, 168, 0.4)',
  'rgba(75, 4, 137, 0.4)',
  'rgba(4, 14, 96, 0.4)',
  'rgba(188, 13, 232, 0.4)',
  'rgba(153, 51, 10, 0.4)',
  'rgba(70, 7, 153, 0.4)',
  'rgba(216, 138, 21, 0.4)',
  'rgba(196, 102, 7, 0.4)',
  'rgba(7, 69, 140, 0.4)',
  'rgba(226, 0, 185, 0.4)',
  'rgba(211, 96, 19, 0.4)',
  'rgba(9, 135, 135, 0.4)',
  'rgba(7, 79, 124, 0.4)',
  'rgba(125, 165, 14, 0.4)',
  'rgba(37, 1, 130, 0.4)',
  'rgba(209, 83, 0, 0.4)',
  'rgba(242, 249, 22, 0.4)',
  'rgba(153, 4, 46, 0.4)',
  'rgba(4, 76, 104, 0.4)',
  'rgba(17, 173, 74, 0.4)',
  'rgba(4, 27, 109, 0.4)',
  'rgba(74, 109, 3, 0.4)',
  'rgba(4, 153, 7, 0.4)',
  'rgba(8, 142, 142, 0.4)',
  'rgba(1, 153, 14, 0.4)',
  'rgba(145, 14, 53, 0.4)',
  'rgba(189, 9, 229, 0.4)',
  'rgba(14, 165, 105, 0.4)',
  'rgba(229, 225, 2, 0.4)',
  'rgba(4, 124, 124, 0.4)',
  'rgba(206, 6, 109, 0.4)',
  'rgba(55, 4, 142, 0.4)',
  'rgba(237, 7, 214, 0.4)',
  'rgba(178, 8, 68, 0.4)',
  'rgba(97, 147, 2, 0.4)',
  'rgba(38, 122, 0, 0.4)',
  'rgba(181, 127, 1, 0.4)',
  'rgba(163, 16, 79, 0.4)',
  'rgba(10, 98, 109, 0.4)',
  'rgba(7, 150, 76, 0.4)',
  'rgba(72, 137, 11, 0.4)',
  'rgba(143, 165, 14, 0.4)',
  'rgba(96, 11, 132, 0.4)',
  'rgba(14, 163, 118, 0.4)',
  'rgba(140, 42, 7, 0.4)',
  'rgba(1, 114, 101, 0.4)',
  'rgba(18, 153, 3, 0.4)',
  'rgba(22, 137, 12, 0.4)',
  'rgba(4, 155, 112, 0.4)',
  'rgba(3, 3, 117, 0.4)',
  'rgba(9, 6, 104, 0.4)',
  'rgba(7, 94, 102, 0.4)',
  'rgba(147, 12, 0, 0.4)',
  'rgba(2, 122, 38, 0.4)',
  'rgba(6, 46, 107, 0.4)',
  'rgba(94, 153, 12, 0.4)',
  'rgba(186, 113, 11, 0.4)',
  'rgba(214, 182, 21, 0.4)',
  'rgba(211, 211, 19, 0.4)',
  'rgba(221, 16, 239, 0.4)',
  'rgba(188, 15, 168, 0.4)',
  'rgba(75, 4, 137, 0.4)',
  'rgba(4, 14, 96, 0.4)',
  'rgba(188, 13, 232, 0.4)',
  'rgba(153, 51, 10, 0.4)',
  'rgba(70, 7, 153, 0.4)',
  'rgba(216, 138, 21, 0.4)',
  'rgba(196, 102, 7, 0.4)',
  'rgba(7, 69, 140, 0.4)',
  'rgba(226, 0, 185, 0.4)',
  'rgba(211, 96, 19, 0.4)',
  'rgba(9, 135, 135, 0.4)',
  'rgba(7, 79, 124, 0.4)',
  'rgba(125, 165, 14, 0.4)',
  'rgba(37, 1, 130, 0.4)',
  'rgba(209, 83, 0, 0.4)',
  'rgba(242, 249, 22, 0.4)',
  'rgba(153, 4, 46, 0.4)',
  'rgba(4, 76, 104, 0.4)',
  'rgba(17, 173, 74, 0.4)',
  'rgba(4, 27, 109, 0.4)',
  'rgba(74, 109, 3, 0.4)',
  'rgba(4, 153, 7, 0.4)',
  'rgba(8, 142, 142, 0.4)',
  'rgba(1, 153, 14, 0.4)',
  'rgba(145, 14, 53, 0.4)',
  'rgba(189, 9, 229, 0.4)',
  'rgba(14, 165, 105, 0.4)',
  'rgba(229, 225, 2, 0.4)',
  'rgba(4, 124, 124, 0.4)',
  'rgba(206, 6, 109, 0.4)',
  'rgba(55, 4, 142, 0.4)',
  'rgba(237, 7, 214, 0.4)',
];
