import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import moment from 'moment';

import { Formik, Form } from 'formik';
import Field from 'antd-components/field';
import Input from 'antd-components/input';
import Select from 'antd-components/select';
import DatePicker from 'antd-components/datePicker';
import Table from 'antd-components/table';
import Button from 'antd-components/button';
import Popconfirm from 'antd-components/popConfirm';
import { callApi, createAction } from 'dorothy/utils';
import withHeader from '../withHeader';
import ActionsBox from '../ActionsBox';
import { USERS_REQUEST, USER_ADD_REQUEST, USER_DELETE_REQUEST, USER_UPDATE_REQUEST } from './ducks';
import Notification from 'antd-components/notification';
import ModalAddUser from './ModalAddUser';
import PaginationBox from 'antd-components/pagination';
import ModalBan from 'components/storefront/Room/ChatBox/ModalBan';

const Container = styled.div`
  padding: 50px 100px;

  .table-box {
    margin-top: 30px;
  }
`;

const Users = ({ userStore, dispatch }) => {
  const [modalData, setModalData] = useState({ isOpen: false, editingId: '' });
  const [userStatics, setUserStatics] = useState({});

  const { isOpen, editingId } = modalData;

  const { users, isFetchingUsers, pagination } = userStore;

  const { total } = pagination;

  const [filterOptions, setFilterOptions] = useState({ role: 'USER', pageSize: 50, page: 1 });
  const [showBanModal, setShowBanModal] = useState(false);
  const [banUsername, setBanUsername] = useState();

  useEffect(() => {
    dispatch(createAction(USERS_REQUEST, filterOptions));
  }, [dispatch, filterOptions]);

  const handleCloseModal = () => {
    setModalData({ isOpen: false, editingId: '' });
  };

  const onSubmit = values => {
    if (editingId !== '') {
      dispatch(
        createAction(USER_UPDATE_REQUEST, {
          ...values,
          callBack: success => {
            if (success) {
              Notification.success('Cập nhật thành công!');
              setFilterOptions({ ...filterOptions });
              setModalData({ isOpen: false, editingId: '' });
            } else Notification.error('Cập nhật thất bại!');
          },
        }),
      );
    } else {
      dispatch(
        createAction(USER_ADD_REQUEST, {
          ...values,
          callBack: success => {
            if (success) {
              Notification.success('Thêm thành công!');
              setFilterOptions({ ...filterOptions });
              setModalData({ isOpen: false, editingId: '' });
            } else Notification.error('Thêm thất bại!');
          },
        }),
      );
    }
  };

  const handleUnBan = user => {
    try {
      if (!user) return;
      callApi('POST', `/api/users/${encodeURIComponent(user.username)}/un-ban`).then(res => {
        setFilterOptions({ ...filterOptions });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const editingUser = users.find(({ _id }) => _id === editingId) || {};
  const { username, role, _id } = editingUser;

  const initialValues = {
    _id,
    username: username || '',
    role: role || 'USER',
    password: '',
    confirmPass: '',
  };

  const columns = [
    {
      title: '#',
      key: '_id',
      render: (text, record, index) => <span>{index + 1}</span>,
    },
    {
      title: 'Tên tài khoản',
      dataIndex: 'username',
      key: 'username',
      render: (text, record, index) => {
        const userType = () => {
          if (record.phonenumber) {
            return (
              <i
                class="fa-solid fa-mobile-screen mr-1"
                style={{
                  color: record.phoneValidate ? '#1890ff' : '#ff4d4f',
                }}
              ></i>
            );
          }
          if (record.ggId) {
            return (
              <i
                class="fa-brands fa-google mr-1"
                style={{
                  color: '#1890ff',
                }}
              ></i>
            );
          }
          if (record.fbId) {
            return (
              <i
                class="fa-brands fa-facebook mr-1"
                style={{
                  color: '#1890ff',
                }}
              ></i>
            );
          }
          return (
            <i
              class="fa-solid fa-user mr-1"
              style={{
                color: '#1890ff',
              }}
            ></i>
          );
        };
        return (
          <div style={{ maxWidth: 200 }}>
            <div style={{ whiteSpace: 'nowrap' }}>
              {userType()}
              {text}
            </div>
          </div>
        );
      },
    },
    {
      title: 'Biệt danh',
      dataIndex: 'fullname',
      key: 'fullname',
      render: (text, record, index) => (
        <div style={{ maxWidth: 200 }}>
          <div>{text}</div>
        </div>
      ),
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      render: text => text || 1,
    },
    {
      title: 'Địa chỉ IP',
      dataIndex: 'ip',
      key: 'ip',
    },
    {
      title: 'Cấm chat',
      dataIndex: 'deactive',
      key: 'deactive',
      render: (text, record, index) =>
        text &&
        text > Date.now() && (
          <div
            style={{
              textAlign: 'center',
            }}
          >
            <div>
              <div>{moment(text).format('DD/MM/YYYY HH:mm:ss a')}</div>
            </div>
            <Button className="mr-2 mb-2" onClick={() => handleUnBan(record)} type="primary">
              Xóa Ban
            </Button>
          </div>
        ),
    },
    {
      title: 'Chức năng',
      render: (name, record) =>
        record.role !== 'ADMIN' && (
          <>
            <Button
              className="mr-2 mb-2"
              onClick={() => setModalData({ ...modalData, editingId: record._id })}
              type="primary"
            >
              Sửa
            </Button>
            <Popconfirm
              placement="topRight"
              title="Bạn có chắc muốn xoá?"
              onConfirm={() => dispatch(createAction(USER_DELETE_REQUEST, record.username))}
              okText="Yes"
              cancelText="No"
            >
              <Button className="mr-2 mb-2" type="danger">
                Xoá
              </Button>
            </Popconfirm>
            <Button
              className="mr-2 mb-2"
              onClick={() => handleBanUser(record.username)}
              type="danger"
            >
              Cấm chat
            </Button>
          </>
        ),
    },
  ];

  const handleSearch = searchValue => {
    setFilterOptions({ ...filterOptions, ...searchValue, page: 1 });
  };

  const handleBanUser = username => {
    setShowBanModal(true);
    setBanUsername(username);
  };

  const renderForm = ({ handleSubmit, resetForm, ...form }) => {
    const { values } = form;
    const { createdAt } = values;

    const handelResetForm = () => {
      resetForm();
      handleSubmit();
    };

    return (
      <Form className="form form-dashboard" style={{ maxWidth: 500, flex: 1 }}>
        <div className="field-group">
          <Field form={form} inline name="username" label="Tên tài khoản" component={Input} />
          <Field form={form} inline name="fullname" label="Biệt danh" component={Input} />
          <Field
            form={form}
            inline
            name="createdAt"
            label="Ngày gởi"
            value={createdAt}
            component={DatePicker}
          />
          <Field form={form} inline name="ip" label="IP" component={Input} />
          <Field
            form={form}
            inline
            name="role"
            label="Vai Trò"
            options={[
              {
                name: 'USER',
                value: 'USER',
              },
              {
                name: 'MOD',
                value: 'MOD',
              },
              {
                name: 'ADMIN',
                value: 'ADMIN',
              },
            ]}
            component={Select}
          />
        </div>
        <div className="action-box" style={{ textAlign: 'right', marginTop: 10 }}>
          <Button
            size="large"
            htmlType="button"
            onClick={handelResetForm}
            style={{ marginRight: 5 }}
          >
            Reset
          </Button>

          <Button size="large" htmlType="submit" type="primary" onClick={handleSubmit}>
            Tìm Kiếm
          </Button>
        </div>
      </Form>
    );
  };

  useEffect(() => {
    callApi('GET', `/api/users/statics`).then(res => {
      if (res?.data) {
        setUserStatics(res.data);
      }
    });
  }, []);

  return (
    <Container>
      {(isOpen || editingId !== '') && (
        <ModalAddUser
          onSubmit={onSubmit}
          visible={isOpen || editingId !== ''}
          title={editingId ? 'Cập nhật tài khoản' : 'Tạo mới tài khoản'}
          onCancel={handleCloseModal}
          editingId={editingId}
          initialValues={initialValues}
        />
      )}
      <ActionsBox
        title="Danh sách tài khoản"
        buttonLabel="Tạo mới"
        // onSearch={handleSearch}
        onClick={() => setModalData({ isOpen: true, editingId: '' })}
      />
      <div
        style={{
          display: 'flex',
          width: '100%',
        }}
      >
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{ role: 'USER' }}
          // validationSchema={validationSchema}
          onSubmit={handleSearch}
          component={renderForm}
        />
        <div className="ml-5">
          <div>Đăng kí SĐT: {userStatics.phonenumber}</div>
          <div>Đăng kí Facebook: {userStatics.facebook}</div>
          <div>Đăng kí Google: {userStatics.google}</div>
          <div>Đăng kí Username: {userStatics.username}</div>
        </div>
      </div>
      <div className="table-box">
        <Table
          bordered
          rowKey={(row, index) => index}
          dataSource={users}
          columns={columns}
          loading={isFetchingUsers}
        />
        <PaginationBox
          pageSize={Number(filterOptions.pageSize)}
          total={total}
          current={filterOptions.page}
          onChange={page => {
            setFilterOptions({ ...filterOptions, page });
          }}
        />
        {showBanModal && (
          <ModalBan
            banUsername={banUsername}
            visible={showBanModal}
            title={'Cấm nhắn tin'}
            onCancel={() => setShowBanModal(false)}
            className="admin-side"
          />
        )}
      </div>
    </Container>
  );
};

export default connect(({ user }) => ({
  userStore: user,
}))(withHeader(Users));
