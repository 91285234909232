import { put, call, takeLatest, fork } from 'redux-saga/effects';
import { callApi, createReducer, createAction } from 'dorothy/utils';

export const ROOMS_REQUEST = 'ROOMS_REQUEST';
export const ROOMS_RESPONSE = 'ROOMS_RESPONSE';

export const ROOM_ADD_REQUEST = 'ROOM_ADD_REQUEST';
export const ROOM_ADD_RESPONSE = 'ROOM_ADD_RESPONSE';

export const ROOM_UPDATE_REQUEST = 'ROOM_UPDATE_REQUEST';
export const ROOM_UPDATE_RESPONSE = 'ROOM_UPDATE_RESPONSE';

export const ROOM_DELETE_REQUEST = 'ROOM_DELETE_REQUEST';
export const ROOM_DELETE_RESPONSE = 'ROOM_DELETE_RESPONSE';

export const REQUEST_ERROR = 'REQUEST_ERROR';

function* requestRoom(action) {
  try {
    const response = yield call(callApi, 'GET', `/api/rooms`, action.payload);

    if (response) yield put(createAction(ROOMS_RESPONSE, response));
  } catch (error) {
    // console.log(error);
  }
}

function* watchRoomsRequest() {
  yield takeLatest(ROOMS_REQUEST, requestRoom);
}

function* addUser(action) {
  const { callBack, ...data } = action.payload;

  try {
    const response = yield call(callApi, 'POST', `/api/rooms`, data);

    if (response) {
      yield put(createAction(ROOM_ADD_RESPONSE, response));
      callBack(true);
    }
  } catch (error) {
    // console.log(error);
    callBack(false);
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchAddUser() {
  yield takeLatest(ROOM_ADD_REQUEST, addUser);
}

function* deleteUser(action) {
  try {
    const response = yield call(callApi, 'DELETE', `/api/rooms/${action.payload}`);

    if (response && response.success) yield put(createAction(ROOM_DELETE_RESPONSE, action.payload));
  } catch (error) {
    // console.log(error);
  }
}

function* watchDeleteUser() {
  yield takeLatest(ROOM_DELETE_REQUEST, deleteUser);
}

function* updateUser(action) {
  const { callBack, ...data } = action.payload;
  const { _id, ...payload } = data;

  try {
    const response = yield call(callApi, 'PATCH', `/api/rooms/${_id}`, payload);

    if (response && response.success) {
      yield put(createAction(ROOM_UPDATE_RESPONSE, { _id, updateData: payload }));
      callBack(true);
    }
  } catch (error) {
    // console.log(error);
    callBack(false);
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchUpdateUser() {
  yield takeLatest(ROOM_UPDATE_REQUEST, updateUser);
}

const initRoom = {
  isFetchingUsers: false,
  rooms: [],
  room: {},
  pagination: { currentPage: 1 },
};
const roomActionHandlers = {
  [ROOMS_REQUEST]: (state, action) => ({ ...state, isFetching: true }),
  [ROOMS_RESPONSE]: (state, action) => {
    const { data, pagination } = action.payload;
    return {
      ...state,
      isFetching: false,
      rooms: data,
      pagination,
    };
  },
  [ROOM_ADD_REQUEST]: (state, action) => ({ ...state, isWaitingAdd: true }),
  [ROOM_ADD_RESPONSE]: (state, action) => {
    const rooms = [...state.rooms, action.payload];
    return {
      ...state,
      isWaitingAdd: false,
      rooms,
    };
  },
  [ROOM_UPDATE_REQUEST]: (state, action) => ({ ...state, isWaitingUpdate: true }),
  [ROOM_UPDATE_RESPONSE]: (state, action) => {
    const rooms = state.rooms.map(room =>
      room._id === action.payload._id ? { ...room, ...action.payload.updateData } : room,
    );
    return {
      ...state,
      isWaitingUpdate: false,
      rooms,
    };
  },
  [ROOM_DELETE_RESPONSE]: (state, action) => {
    const rooms = state.rooms.filter(({ _id }) => _id !== action.payload);
    return {
      ...state,
      isWaitingAdd: false,
      rooms,
    };
  },
};

export const roomReducer = createReducer(initRoom, roomActionHandlers);
export const roomSagas = [
  fork(watchRoomsRequest),
  fork(watchAddUser),
  fork(watchDeleteUser),
  fork(watchUpdateUser),
];
