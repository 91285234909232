import { put, call, takeLatest, fork } from 'redux-saga/effects';
import { callApi, createReducer, createAction } from 'dorothy/utils';

export const GET_SETTING_REQUEST = 'GET_SETTING_REQUEST';
export const GET_SETTING_RESPONSE = 'GET_SETTING_RESPONSE';

export const UPDATE_SETTING_REQUEST = 'UPDATE_SETTING_REQUEST';
export const UPDATE_SETTING_RESPONSE = 'UPDATE_SETTING_RESPONSE';

export const REQUEST_ERROR = 'REQUEST_ERROR';

function* updateSetting(action) {
  const { callback, id, ...data } = action.payload;

  try {
    const response = yield call(callApi, 'PUT', `/api/settings/${id}`, data);

    if (response && response.success) {
      yield put(createAction(UPDATE_SETTING_RESPONSE));
      callback(true);
    }
  } catch (error) {
    // console.log(error);
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchUpdateSetting() {
  yield takeLatest(UPDATE_SETTING_REQUEST, updateSetting);
}

function* getSetting() {
  try {
    const response = yield call(callApi, 'GET', `/api/settings`);

    if (response && response[0]) {
      yield put(createAction(GET_SETTING_RESPONSE, response[0]));
    }
  } catch (error) {
    // console.log(error);
    yield put(createAction(REQUEST_ERROR));
  }
}

function* watchGetSetting() {
  yield takeLatest(GET_SETTING_REQUEST, getSetting);
}

const initSetting = {
  setting: {},
  isUpdating: false,
  isFetching: false,
};
const settingActionHandlers = {
  [GET_SETTING_REQUEST]: (state, action) => {
    return { ...state, isFetching: true };
  },
  [GET_SETTING_RESPONSE]: (state, action) => {
    return { ...state, setting: action.payload, isFetching: false };
  },
  [UPDATE_SETTING_RESPONSE]: state => {
    return { ...state, isUpdating: false };
  },
  [UPDATE_SETTING_REQUEST]: state => {
    return { ...state, isUpdating: true };
  },
  [REQUEST_ERROR]: state => {
    return { ...state, isUpdating: false, isFetching: false };
  },
};

export const settingReducer = createReducer(initSetting, settingActionHandlers);
export const settingSagas = [fork(watchGetSetting), fork(watchUpdateSetting)];
