import { colors as colorsCon } from 'constant/colors';
let colors = colorsCon;
if (typeof window.colorsCommon !== 'undefined') {
  colors = [...window.colorsCommon];
}

const limitCountLevel = 500;

const getLevel = (totalMessages, limitCountLevel = 500, initialLevelThreshold = 20) => {
  try {
    if (totalMessages < 1) return 1; // Level mặc định là 1 khi chưa có tin nhắn nào được gửi
    let level = 1; // Bắt đầu từ level 1
    let messagesThreshold = limitCountLevel * initialLevelThreshold; // Tính ngưỡng tin nhắn cho 20 levels đầu tiên
    
    if (totalMessages < messagesThreshold) {
      level += Math.floor(totalMessages / limitCountLevel);
    } else {
      totalMessages -= messagesThreshold; // Bỏ qua số tin nhắn đã được dùng để đạt level 20
      level += initialLevelThreshold - 1 + Math.floor(totalMessages / (limitCountLevel * 4));
    }
    return level;
  } catch (error) {
    return 1;
  }
};

export const useUser = (data = {}) => {
  const isAdmin = data?.role === 'ADMIN';
  const isMod = data?.role === 'MOD';
  const isManager = isAdmin || isMod;
  const deactive = data?.deactive;
  const messageCount = data?.messageCount;
  const level = getLevel(messageCount) || 1;
  const messageCountLevel =
    messageCount < limitCountLevel ? messageCount : messageCount - (level - 1) * limitCountLevel;

  const sendAllow = () => {
    if (isManager || !deactive) return true;
    try {
      const now = Date.now();
      return now > deactive;
    } catch (error) {
      return true;
    }
  };

  return {
    isAdmin,
    isMod,
    isManager,
    sendAllow,
    deactive,
    messageCount,
    messageCountLevel,
    color: colors[level - 1],
    level: level,
  };
};
