import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Select from 'antd-components/select';

import Button from 'antd-components/button';
import Field from 'antd-components/field';
import Input from 'antd-components/input';
import { TextAreaInput } from 'antd-components/input';
import Modal from 'antd-components/modal';
import { validation } from 'dorothy/utils/validation';
import { Radio } from 'antd';

const StyledForm = styled.div`
  .action-box {
    display: flex;
    justify-content: flex-end;

    > button:nth-child(2) {
      margin-left: 20px;
    }
  }

  .ban-box {
    display: flex;
    margin-bottom: 40px !important;

    p {
      width: 150px;
      font-weight: 600;
      font-size: 15px;
      margin-bottom: 0;
      color: #272727;
    }
  }
`;

const ONE_MINUTE_IN_MILISECOND = 60 * 1000;
const ONE_HOUR_IN_MILISECOND = 1000 * 60 * 60;
const ONE_DAY_IN_MILISECOND = 1000 * 60 * 60 * 24;

const deactiveTimeList = [
  {
    label: '5 phút',
    value: 5 * ONE_MINUTE_IN_MILISECOND,
  },
  {
    label: '1 giờ',
    value: ONE_HOUR_IN_MILISECOND,
  },
  {
    label: '1 ngày',
    value: ONE_DAY_IN_MILISECOND,
  },
  {
    label: '7 ngày',
    value: 7 * ONE_DAY_IN_MILISECOND,
  },
];

export default function ModalIpForm(props) {
  const { onSubmit, visible, title, onCancel } = props;
  const [deactiveTime, setDeactiveTime] = useState(5 * ONE_MINUTE_IN_MILISECOND);

  const validationSchema = yup.object().shape({
    ip: validation.ip,
  });

  const handleChange = option => {
    setDeactiveTime(option.target.value);
  };

  const handleSubmit = data => {
    onSubmit({ ...data, deactive: Date.now() + deactiveTime });
  };
  const renderForm = ({ handleSubmit, ...form }) => (
    <Form className="form">
      <div className="field-group">
        <Field form={form} inline name="ip" label="Ban IP" component={Input} />
        <div className="ban-box">
          <p className="label">Cấm nhắn tin</p>
          <div className="field-content">
            <Radio.Group onChange={handleChange} value={deactiveTime}>
              {deactiveTimeList.map(option => (
                <Radio key={option.value} value={option.value}>
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </div>
        </div>
        <Field form={form} inline name="reason" label="Lý do" rows={3} component={TextAreaInput} />
      </div>
      <div className="action-box">
        <Button size="large" htmlType="submit" type="primary" onClick={handleSubmit}>
          Lưu
        </Button>
        <Button size="large" onClick={onCancel}>
          Huỷ bỏ
        </Button>
      </div>
    </Form>
  );

  return (
    <Modal title={title} visible={visible} onCancel={onCancel} className="admin-side">
      <StyledForm>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{}}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          component={renderForm}
        />
      </StyledForm>
    </Modal>
  );
}
