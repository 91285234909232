import React from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import Button from 'antd-components/button';
import Field from 'antd-components/field';
import Input from 'antd-components/input';
import Modal from 'antd-components/modal';
import { UPDATE_PROFILE_REQUEST } from 'components/dashboard/Users/ducks';
import { useDispatch, connect } from 'react-redux';
import { validation } from 'dorothy/utils/validation';
import Notification from 'antd-components/notification';

const StyledForm = styled.div`
  .action-box {
    display: flex;
    justify-content: flex-end;

    > button:nth-child(2) {
      margin-left: 10px;
    }
  }

  .field-group {
    margin-bottom: 15px;
  }
`;

const ModalUpdateInfo = props => {
  const { visible, title, onCancel, userStore } = props;
  const dispatch = useDispatch();

  const initialValues = {
    fullname: userStore.user.fullname || userStore.user.username,
  };
  const validationSchema = yup.object().shape({
    fullname: validation.required,
  });

  const handleUpdate = data => {
    dispatch({
      type: UPDATE_PROFILE_REQUEST,
      payload: {
        fullname: data.fullname,
        callBack: (success, data) => {
          console.log('success', success, data);
          if (success) {
            onCancel();
            Notification.success('Cập nhật thành công');
          } else {
            Notification.error('Tên chỉ được thay đổi 2 lần duy nhất');
          }
        },
      },
    });
  };

  const renderForm = ({ handleSubmit, ...form }) => (
    <Form className="form">
      <>
        <div className="field-group">
          <Field form={form} name="fullname" label="Biệt danh" maxlength={20} component={Input} />
          <span style={{ color: '#cf1322' }}>Lưu ý: tên chỉ được thay đổi 2 lần duy nhất</span>
        </div>
        <div className="action-box">
          <Button size="small" htmlType="submit" type="primary" onClick={handleSubmit}>
            Lưu
          </Button>
          <Button size="small" onClick={onCancel}>
            Huỷ bỏ
          </Button>
        </div>
      </>
    </Form>
  );

  return (
    <Modal title={title} visible={visible} onCancel={onCancel}>
      <StyledForm>
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleUpdate}
          component={renderForm}
        />
      </StyledForm>
    </Modal>
  );
};

export default connect(({ user }) => ({
  userStore: user,
}))(React.memo(ModalUpdateInfo));
