import React from 'react';
import { DatePicker } from 'antd';
import classNames from 'classnames';
import styled from 'styled-components';
import 'antd/es/input/style/css';
import moment from 'moment';

const StyledInput = styled(DatePicker)`
  /* stylelint-disable */
`;

export default ({ field, form, modern, simple, value, className, ...props }) => {
  const handleChange = value => {
    field.onChange({
      target: { value: value ? value.valueOf() : undefined, name: field.name },
    });
  };

  return (
    <StyledInput
      {...props}
      value={typeof value === 'number' ? moment(value) : value}
      onChange={handleChange}
      className={classNames(className, { modern, simple })}
    />
  );
};

export const { RangePicker } = DatePicker;

const StyledRangePicker = styled(RangePicker)`
  /* stylelint-disable */
`;
export const RangePickerInput = ({ field, form, modern, simple, value, className, ...props }) => {
  const handleChange = value => {
    const [startDate, endDate] = value;
    field.onChange({
      target: { value: [startDate.valueOf(), endDate.valueOf()], name: field.name },
    });
  };

  return (
    <StyledRangePicker
      {...props}
      onChange={handleChange}
      className={classNames(className, { modern, simple })}
    />
  );
};
