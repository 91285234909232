import React, { useState } from 'react';
import { Message } from '@chatscope/chat-ui-kit-react';
import { useMessage, useUser } from 'hooks';
import { Tag, Modal } from 'antd';
import styled from 'styled-components';
import Linkify from 'react-linkify';
import { getAvatar, getAvatarFull, testPhoneNumber } from 'utils/common';
import LevelBadge from './LevelBadge';
const MessageJoin = styled.div`
  background: rgba(222, 166, 99, 0.2);
  padding: 2px 5px;
  margin-top: 0.4em;
  border-radius: 4px;
`;

const MessageAvatar = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;


const MessageItem = ({ data = {}, user, onClickMessage, onShowBanModal, onShowDeleteModal }) => {
  const userMessage = useMessage(data);
  const {
    id,
    idTmp,
    message,
    sender,
    // avatar,
    // socialAvatar,
    role,
    type,
    isMessageSystem,
    // isMessageAdmin,
    isMessageManager,
    level,
    color,
    colorText,
    colorFade,
    // userId,
  } = userMessage;
  const { isAdmin, isMod } = useUser(user);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState('');
  const senderConvert = testPhoneNumber(sender) ? `${sender.slice(0, 7)}***` : sender;
  const getColorFromName = (name) => {
    let hash = 0;
    const colors = ['#007bff', '#6610f2', '#6f42c1', '#e83e8c', '#dc3545', '#fd7e14', '#ffc107', '#28a745'];
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    const index = Math.abs(hash % colors.length);
    return colors[index];
  };  
  const showModal = () => {
    const avatar = getAvatarFull(userMessage);
    if (avatar) {
      setAvatarUrl(avatar);
      setIsModalVisible(true);
    }
  };
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const getInitials = (name) => {
    const match = name.match(/[A-Za-z]/);
    if (match) {
      return match[0].toUpperCase();
    }
    return '?'; 
  };
  const backgroundColor = getColorFromName(sender);
  if (type === 'SYSTEM_NEW_MEMBER') {
    return (
      <MessageJoin style={{ backgroundColor: colorFade }}>
        <span className="cs-message__sender-name">{senderConvert}: </span>
        <span className="join-desc">Tham gia trò chuyện</span>
      </MessageJoin>
    );
  }

  // const renderAvatar = () => {
  //   if (avatar.indexOf('googleusercontent') > -1) {
  //     return `${process.env.REACT_APP_IMG_URL}/uploads/avatar.png`;
  //   }

  //   if (avatar.indexOf('platform-lookaside.fbsbx.com') > -1) {
  //     return `${process.env.REACT_APP_IMG_URL}/uploads/avatar.png`;
  //   }

  //   return `${process.env.REACT_APP_IMG_URL}${avatar}`;
  // };

  return (
    <>
      <Message
        model={{
          direction: 'incoming',
          position: 'first',
        }}
        className={`${isMessageSystem ? `cs-message-system` : ''} ${
          isMessageManager ? 'cs-message-admin' : ''
        } ${idTmp && !id ? 'cs-message-tmp' : ''}`}
      >
        <Message.TextContent>
          <MessageAvatar>
            <div className="cs-message__avatar">
              <div className="cs-avatar cs-avatar--sm" style={{
                borderRadius: '50%',
                backgroundColor: backgroundColor,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                fontSize: '14px',
                fontWeight: '700',
                fontFamily: 'Karla'
              }}
              onClick={showModal}
              >
                {getAvatar(userMessage) ? (
                  <img src={getAvatar(userMessage)} alt={senderConvert} size="sm" referrerPolicy="no-referrer" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
                ) : (
                  getInitials(sender)
                )}
              </div>
            </div>
            <Tag color={color} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '3px',border: '0px', height: '16px' }}>
              <LevelBadge level={level || 1} />
              {isMessageManager ? (
                ''
              ) : (
                <span
                  style={{
                    color: colorText || '#fff',
                    display: 'flex',
                  }}
                >{`${level || 1}`}</span>
              )}
            </Tag>
          </MessageAvatar>
          <div className="cs-message__content-sender_message">
            <span
              className="cs-message__sender-name"
              onClick={() => onClickMessage && onClickMessage(data)}
            >
              {senderConvert}
              {(level > 10 || role == 'ADMIN' || role == 'MOD') && (
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><g clipPath="url(#clip0_21_10569)"><path d="M15.5 8C15.5 8.64 14.7137 9.1675 14.5562 9.7575C14.3937 10.3675 14.805 11.2175 14.4963 11.7513C14.1825 12.2938 13.2387 12.3588 12.7987 12.7987C12.3588 13.2387 12.2938 14.1825 11.7513 14.4963C11.2175 14.805 10.3675 14.3937 9.7575 14.5562C9.1675 14.7137 8.64 15.5 8 15.5C7.36 15.5 6.8325 14.7137 6.2425 14.5562C5.6325 14.3937 4.7825 14.805 4.24875 14.4963C3.70625 14.1825 3.64125 13.2387 3.20125 12.7987C2.76125 12.3588 1.8175 12.2938 1.50375 11.7513C1.195 11.2175 1.60625 10.3675 1.44375 9.7575C1.28625 9.1675 0.5 8.64 0.5 8C0.5 7.36 1.28625 6.8325 1.44375 6.2425C1.60625 5.6325 1.195 4.7825 1.50375 4.24875C1.8175 3.70625 2.76125 3.64125 3.20125 3.20125C3.64125 2.76125 3.70625 1.8175 4.24875 1.50375C4.7825 1.195 5.6325 1.60625 6.2425 1.44375C6.8325 1.28625 7.36 0.5 8 0.5C8.64 0.5 9.1675 1.28625 9.7575 1.44375C10.3675 1.60625 11.2175 1.195 11.7513 1.50375C12.2938 1.8175 12.3588 2.76125 12.7987 3.20125C13.2387 3.64125 14.1825 3.70625 14.4963 4.24875C14.805 4.7825 14.3937 5.6325 14.5562 6.2425C14.7137 6.8325 15.5 7.36 15.5 8Z" fill="#2568EF"/><path d="M10.1674 5.76486L7.31242 8.61986L5.83242 7.14111C5.51117 6.81986 4.98992 6.81986 4.66867 7.14111C4.34742 7.46236 4.34742 7.98361 4.66867 8.30486L6.74492 10.3811C7.05742 10.6936 7.56492 10.6936 7.87742 10.3811L11.3299 6.92861C11.6512 6.60736 11.6512 6.08611 11.3299 5.76486C11.0087 5.44361 10.4887 5.44361 10.1674 5.76486Z" fill="#FFFCEE"/></g><defs><clipPath id="clip0_21_10569"><rect width="16" height="16" fill="white"/></clipPath></defs></svg>
              )}
            </span>
            {(isAdmin || isMod) && (
              <span className="cs-message__action">
                <i
                  className="fa-solid fa-user-lock ml-2 cursor-pointer"
                  onClick={() => onShowBanModal(data)}
                ></i>
                <i
                  className="fa-solid fa-trash-can ml-2 cursor-pointer"
                  onClick={() => onShowDeleteModal(data)}
                ></i>
              </span>
            )}
            <span className="cs-message__text-message ml-1">
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    className="message-linkify"
                    target="blank"
                    rel="nofollow"
                    href={decoratedHref}
                    key={key}
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {message}
              </Linkify>
            </span>
          </div>
        </Message.TextContent>
      </Message>
      <Modal title="Avatar" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>
        <img src={avatarUrl} alt="Avatar" style={{ width: '100%' }} />
      </Modal>
    </>
  );
};

export default MessageItem;
